@charset "UTF-8";
/*
Project: 	 AREA 17
Version: 	 1.0
Author:
Description:
-------------------------------------------------------------------*/
/*
[Notes]

-------------------------------------------------------------------*/
@import '~highlight.js/styles/monokai-sublime.css';
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  outline: 0;
}

article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
main {
  display: block;
}

html {
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
  box-sizing: border-box;
  touch-action: manipulation;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

ul,
ol {
  list-style: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  touch-action: inherit;
}

.svg-sprite {
  position: absolute;
  z-index: -1;
  left: -1px;
  top: -1px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@-ms-viewport {
  width: device-width;
}
/*
  A17 Guide

  This product is particular because we have 2 grids in one same site :
  1. one grid for the landing/listing pages and the homepage
  2. one grid for the posts/articles/guides with a fixed navigation showing on large, xlarge breakpoints
*/
/* header height */
/* header vertical padding */
/* header horizontal padding */
/* Baseline grid height */
/*
 Max widths the main column can run to over the breakpoints
 values can either be 'fluid' or a pixel value
 recommended xlarge is a px value and xsmall is fluid
*/
/* Post max-width */
/* ------ Post grid - connected to grid-post mixins ------ */
/* navbar */
/* Inner gutters, in px or vw, of each breakpoint */
/* Outer gutters, in px or vw, of each breakpoint */
/* How many columns are in each breakpoint */
/* Texts in posts are not full width */
/*$column-spans__text: (
  xsmall: 6,
  small: 6,
  medium: 9,
  large: 9,
  xlarge: 9
);*/
/* ------ Listing grid - connected to grid mixins ------ */
/* Inner gutters, in px or vw, of each breakpoint */
/* Outer gutters, in px or vw, of each breakpoint */
/* How many columns are in each breakpoint */
/*
  Breakpoint information, where each starts and stops
  if a breakpoint is not fluid, then the start value is equal to the main col value plus 2x the gutter at this breakpoint
*/
/* Uniform border radius */
/* Some easing functions */
/* z-index */
:root {
  --spacing-01: 4px;
  --spacing-02: 8px;
  --spacing-03: 12px;
  --spacing-04: 16px;
  --spacing-05: 20px;
  --spacing-06: 24px;
  --spacing-07: 28px;
  --spacing-08: 32px;
  --spacing-09: 36px;
  --spacing-10: 40px;
  --spacing-11: 48px;
  --spacing-12: 60px;
  --spacing-13: 80px;
  --spacing-14: 100px;
  --spacing-15: 120px;
  --spacing-16: 140px;
  --spacing-17: 160px;
  --spacing-18: 180px;
  --spacing-19: 200px;
}

/*
  Colors by shade.
*/
:root {
  --color__white: #fff;
  --color__black: #000;
  --color__black--54: #757575;
  --color__black--20: #CCC;
  --color__black--15: #D8D8D8;
  --color__black--10: #E5E5E5;
  --color__black--05: #F2F2F2;
  --color__black--03: #F7F7F7;
  --color__black--a-75: rgba(0, 0, 0, .75);
  --color__grey--a-97: rgba(242, 242, 242, .97);
  --color__blue: #0048B8;
  --color__yellow: #FFFF00;
}

/*
  Colors by usage.
*/
:root {
  --color__background: var(--color__white);
  --color__background--dark: var(--color__black);
  --color__background--medium: var(--color__black--15);
  --color__background--light: var(--color__black--05);
  --color__background--ultra-light: var(--color__black--03);
  --color__background--error: var(--color__blue);
  --color__text: var(--color__black);
  --color__text--on-dark: var(--color__white);
  --color__text--light: var(--color__black--54);
  --color__text--highlight: var(--color__blue);
  --color__text--placeholder: var(--color__black--20);
  --color__border: var(--color__black--10);
  --color__brand: var(--color__black);
  --color__overlay: var(--color__black--a-75);
  --color__overlay--light: var(--color__grey--a-97);
}

/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  A17 Guide

  This product is particular because we have 2 grids in one same site :
  1. one grid for the landing/listing pages and the homepage : grid.scss
  2. one grid for the posts/articles/guides with a fixed navigation showing on large, xlarge breakpoints : grid-post.scss

  On this project : I added a new parameter called outerBump that will move the entire grid
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  A17 Guide

  This product is particular because we have 2 grids in one same site :
  1. one grid for the landing/listing pages and the homepage : grid.scss
  2. one grid for the posts/posts/guides with a fixed navigation showing on large, xlarge breakpoints : grid-post.scss

  On this project : I added a new parameter called outerBump that will move the entire grid
*/
/*
  @function colspan-post

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan-post + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-post

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-post-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-posts: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-posts__item {
    @include width-post-multi($column-spans__list-posts);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-post

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-post-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-posts: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-posts__item {
    @include push-post-multi($column-spans__list-posts);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-post-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-post

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-posts {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column-post

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-posts: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-posts__item {
    @include column($column-spans__list-posts);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-post-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-posts {
    @include columns-container-post-flex;
  }
  ```
*/
/*
  @mixin column-flex-post

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-posts: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-posts__item {
    @include column-flex($column-spans__list-posts);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/* A17 Guide : Modified so the system is working with the left fixed navigation that is pushing content */
/* second grid mixins */
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
    @mixin bordered

    Parameters:
    $color - color
    $color--hover - color for hover
    $pos - vertical position (in % or in px)
*/
/* Font face */
@font-face {
  font-family: "Waldenburg";
  src: url("/dist/fonts/waldenburg/Waldenburg-Regular.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Waldenburg";
  src: url("/dist/fonts/waldenburg/Waldenburg-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "WaldenburgBoldSemiCondensed";
  src: url("/dist/fonts/waldenburg-semi-condensed/Waldenburg-Bold-SemiCondensed.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/***
  Variables for font families.
***/
/***
  Set to 100% to use the browsers font size as a base
***/
/***
  Font styles to use inside typo styles.
***/
/* Heading 01 */
.f-heading-01 {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 3.75rem;
  line-height: 4.25rem;
  font-weight: 700;
  letter-spacing: 0;
}
.f-heading-01 b,
.f-heading-01 strong {
  font-weight: 600;
}
.f-heading-01 i,
.f-heading-01 em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .f-heading-01 {
    font-size: 5rem;
    line-height: 5.5rem;
    font-weight: 700;
    letter-spacing: 0;
  }
}

/* Heading 02 */
.f-heading-02 {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 2rem;
  line-height: 2.25rem;
  font-weight: 700;
  letter-spacing: 0.01em;
}
.f-heading-02 b,
.f-heading-02 strong {
  font-weight: 600;
}
.f-heading-02 i,
.f-heading-02 em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .f-heading-02 {
    font-size: 3.75rem;
    line-height: 4.25rem;
    font-weight: 700;
    letter-spacing: 0;
  }
}

/* Heading 03 */
.f-heading-03 {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 1.625rem;
  line-height: 1.875rem;
  font-weight: 700;
  letter-spacing: 0.01em;
}
.f-heading-03 b,
.f-heading-03 strong {
  font-weight: 600;
}
.f-heading-03 i,
.f-heading-03 em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .f-heading-03 {
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: 700;
    letter-spacing: 0.01em;
  }
}

/* Heading 03 alt */
.f-heading-03-alt {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 2rem;
  line-height: 2.25rem;
  font-weight: 700;
  letter-spacing: 0.01em;
}
.f-heading-03-alt b,
.f-heading-03-alt strong {
  font-weight: 600;
}
.f-heading-03-alt i,
.f-heading-03-alt em {
  font-style: italic;
}

/* Heading 04 */
.f-heading-04 {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}
.f-heading-04 b,
.f-heading-04 strong {
  font-weight: 600;
}
.f-heading-04 i,
.f-heading-04 em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .f-heading-04 {
    font-size: 1.625rem;
    line-height: 1.875rem;
    font-weight: 700;
    letter-spacing: 0.01em;
  }
}

/* Intro */
.f-intro {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
}
.f-intro b,
.f-intro strong {
  font-weight: 600;
}
.f-intro i,
.f-intro em {
  font-style: italic;
}
@media screen and (min-width: 1024px) {
  .f-intro {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 400;
  }
}

/* Body 01 em */
.f-body-01-em {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}
.f-body-01-em b,
.f-body-01-em strong {
  font-weight: 600;
}
.f-body-01-em i,
.f-body-01-em em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .f-body-01-em {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
  }
}

/* Body 01 */
.f-body-01 {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.f-body-01 b,
.f-body-01 strong {
  font-weight: 600;
}
.f-body-01 i,
.f-body-01 em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .f-body-01 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
}

/* Body 02 em */
.f-body-02-em {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}
.f-body-02-em b,
.f-body-02-em strong {
  font-weight: 700;
}
.f-body-02-em i,
.f-body-02-em em {
  font-style: italic;
}

/* Body 02 */
.f-body-02 {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.f-body-02 b,
.f-body-02 strong {
  font-weight: 600;
}
.f-body-02 i,
.f-body-02 em {
  font-style: italic;
}

/* Caption */
.f-caption {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
}
.f-caption b,
.f-caption strong {
  font-weight: 600;
}
.f-caption i,
.f-caption em {
  font-style: italic;
}

/* Color */
.f-color {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.05em;
}
.f-color b,
.f-color strong {
  font-weight: 600;
}
.f-color i,
.f-color em {
  font-style: italic;
}

/* Old styles */
.design-select {
  position: fixed;
  bottom: 30px;
  left: 10px;
  z-index: 10000;
}

.design-grid-toggle {
  position: fixed;
  z-index: 9999;
  left: 0;
  bottom: 0;
  padding: 1px 8px;
  background: var(--color__background--dark);
  color: var(--color__text--on-dark);
  font-size: 11px;
  cursor: pointer;
}

.design-grid-toggle--columns {
  left: auto;
  right: 0;
}
.design-grid-toggle--columns::before, .design-grid-toggle--columns::after {
  display: block;
  position: absolute;
  right: 8px;
  bottom: 100%;
  color: #0f0;
  font-weight: bold;
  text-align: right;
  text-transform: uppercase;
  white-space: nowrap;
}
.design-grid-toggle--columns::before {
  margin-bottom: -2px;
}
@media screen and (max-width: 599px) {
  .design-grid-toggle--columns::before {
    content: "xsmall";
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .design-grid-toggle--columns::before {
    content: "small";
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .design-grid-toggle--columns::before {
    content: "medium";
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .design-grid-toggle--columns::before {
    content: "large";
  }
}
@media screen and (min-width: 1280px) {
  .design-grid-toggle--columns::before {
    content: "xlarge";
  }
}
.design-grid-toggle--columns::after {
  content: attr(data-env);
  margin-bottom: 12px;
}

.design-grid--baseline {
  display: block;
  position: absolute;
  z-index: 9998;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 5px, #7fffff 5px, #7fffff 10px);
  opacity: 0.25;
  pointer-events: none;
}
.design-grid--baseline.js-hide {
  display: none;
}

.design-grid--columns {
  display: block;
  position: fixed;
  z-index: 9998;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: transparent;
  opacity: 0.25;
  pointer-events: none;
}
.design-grid--columns.js-hide {
  display: none;
}
@media screen and (max-width: 599px) {
  .design-grid--columns {
    width: calc(100vw - 40px);
    background: repeating-linear-gradient(90deg, #7fffff, #7fffff calc((100vw - 140px) / 6), rgba(0, 0, 0, 0) calc((100vw - 140px) / 6), rgba(0, 0, 0, 0) calc( ( (100vw - 140px) / 6) + 20px ));
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .design-grid--columns {
    width: calc(100vw - 80px);
    background: repeating-linear-gradient(90deg, #7fffff, #7fffff calc((100vw - 180px) / 6), rgba(0, 0, 0, 0) calc((100vw - 180px) / 6), rgba(0, 0, 0, 0) calc( ( (100vw - 180px) / 6) + 20px ));
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .design-grid--columns {
    width: calc(100vw - 80px);
    background: repeating-linear-gradient(90deg, #7fffff, #7fffff calc((100vw - 410px) / 12), rgba(0, 0, 0, 0) calc((100vw - 410px) / 12), rgba(0, 0, 0, 0) calc( ( (100vw - 410px) / 12) + 30px ));
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .design-grid--columns {
    width: calc(100vw - 120px);
    background: repeating-linear-gradient(90deg, #7fffff, #7fffff calc((100vw - 560px) / 12), rgba(0, 0, 0, 0) calc((100vw - 560px) / 12), rgba(0, 0, 0, 0) calc( ( (100vw - 560px) / 12) + 40px ));
  }
}
@media screen and (min-width: 1280px) {
  .design-grid--columns {
    width: 1240px;
    background: repeating-linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 40px, #7fffff 40px, #7fffff calc( ( (1160px - 440px) / 12) + 40px ));
  }
}

@media screen and (max-width: 599px) {
  .design-grid--columns--withNav {
    left: 0px;
    width: calc(100vw - 40px - 0px);
    background: repeating-linear-gradient(90deg, #7fffff, #7fffff calc((100vw - 0px - 140px) / 6), rgba(0, 0, 0, 0) calc((100vw - 0px - 140px) / 6), rgba(0, 0, 0, 0) calc( ( (100vw - 0px - 140px) / 6) + 20px ));
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .design-grid--columns--withNav {
    left: 0px;
    width: calc(100vw - 80px - 0px);
    background: repeating-linear-gradient(90deg, #7fffff, #7fffff calc((100vw - 0px - 180px) / 6), rgba(0, 0, 0, 0) calc((100vw - 0px - 180px) / 6), rgba(0, 0, 0, 0) calc( ( (100vw - 0px - 180px) / 6) + 20px ));
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .design-grid--columns--withNav {
    left: 0px;
    width: calc(100vw - 80px - 0px);
    background: repeating-linear-gradient(90deg, #7fffff, #7fffff calc((100vw - 0px - 520px) / 12), rgba(0, 0, 0, 0) calc((100vw - 0px - 520px) / 12), rgba(0, 0, 0, 0) calc( ( (100vw - 0px - 520px) / 12) + 40px ));
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .design-grid--columns--withNav {
    left: 250px;
    width: calc(100vw - 110px - 250px);
    background: repeating-linear-gradient(90deg, #7fffff, #7fffff calc((100vw - 250px - 550px) / 12), rgba(0, 0, 0, 0) calc((100vw - 250px - 550px) / 12), rgba(0, 0, 0, 0) calc( ( (100vw - 250px - 550px) / 12) + 40px ));
  }
}
@media screen and (min-width: 1280px) {
  .design-grid--columns--withNav {
    left: 250px;
    width: 1000px;
    background: repeating-linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 40px, #7fffff 40px, #7fffff calc( ( (1170px - 250px - 440px) / 12) + 40px ));
  }
}

.icon--ae, .icon--ae svg {
  width: 20px;
  height: 26px;
}

.icon--ai, .icon--ai svg {
  width: 20px;
  height: 26px;
}

.icon--arrow-down, .icon--arrow-down svg {
  width: 15px;
  height: 15px;
}

.icon--arrow-up, .icon--arrow-up svg {
  width: 15px;
  height: 15px;
}

.icon--ase, .icon--ase svg {
  width: 20px;
  height: 26px;
}

.icon--burger, .icon--burger svg {
  width: 24px;
  height: 24px;
}

.icon--close, .icon--close svg {
  width: 24px;
  height: 24px;
}

.icon--copy, .icon--copy svg {
  width: 24px;
  height: 24px;
}

.icon--dir, .icon--dir svg {
  width: 26px;
  height: 21px;
}

.icon--dmg, .icon--dmg svg {
  width: 20px;
  height: 26px;
}

.icon--doc, .icon--doc svg {
  width: 20px;
  height: 26px;
}

.icon--download, .icon--download svg {
  width: 24px;
  height: 24px;
}

.icon--dropdown-small, .icon--dropdown-small svg {
  width: 24px;
  height: 24px;
}

.icon--dropdown, .icon--dropdown svg {
  width: 12px;
  height: 8px;
}

.icon--eps, .icon--eps svg {
  width: 20px;
  height: 26px;
}

.icon--fla, .icon--fla svg {
  width: 20px;
  height: 26px;
}

.icon--fnt, .icon--fnt svg {
  width: 20px;
  height: 26px;
}

.icon--gen, .icon--gen svg {
  width: 20px;
  height: 26px;
}

.icon--grid, .icon--grid svg {
  width: 24px;
  height: 19px;
}

.icon--html, .icon--html svg {
  width: 20px;
  height: 26px;
}

.icon--img, .icon--img svg {
  width: 20px;
  height: 26px;
}

.icon--indd, .icon--indd svg {
  width: 20px;
  height: 26px;
}

.icon--key, .icon--key svg {
  width: 20px;
  height: 26px;
}

.icon--merlin, .icon--merlin svg {
  width: 20px;
  height: 26px;
}

.icon--net, .icon--net svg {
  width: 26px;
  height: 21px;
}

.icon--numbers, .icon--numbers svg {
  width: 20px;
  height: 26px;
}

.icon--pages, .icon--pages svg {
  width: 20px;
  height: 26px;
}

.icon--pdf, .icon--pdf svg {
  width: 20px;
  height: 26px;
}

.icon--ppt, .icon--ppt svg {
  width: 20px;
  height: 26px;
}

.icon--psd, .icon--psd svg {
  width: 20px;
  height: 26px;
}

.icon--search, .icon--search svg {
  width: 24px;
  height: 24px;
}

.icon--snd, .icon--snd svg {
  width: 20px;
  height: 26px;
}

.icon--sql, .icon--sql svg {
  width: 20px;
  height: 26px;
}

.icon--swf, .icon--swf svg {
  width: 20px;
  height: 26px;
}

.icon--toc, .icon--toc svg {
  width: 24px;
  height: 24px;
}

.icon--txt, .icon--txt svg {
  width: 20px;
  height: 26px;
}

.icon--vid, .icon--vid svg {
  width: 20px;
  height: 26px;
}

.icon--xls, .icon--xls svg {
  width: 20px;
  height: 26px;
}

.icon--zip, .icon--zip svg {
  width: 20px;
  height: 26px;
}

.container,
#a17 {
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 599px) {
  .container,
#a17 {
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .container,
#a17 {
    width: auto;
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .container,
#a17 {
    width: auto;
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .container,
#a17 {
    width: auto;
    padding-right: 60px;
    padding-left: 60px;
  }
}
@media screen and (min-width: 1280px) {
  .container,
#a17 {
    width: 1160px;
    padding-right: 0;
    padding-left: 0;
  }
}

/* Global */
.ds .hub,
.ds .color,
.ds .files,
.ds .post__text {
  margin-top: 0;
}

.ds__single + .ds__single {
  margin-top: var(--spacing-12);
  padding-top: var(--spacing-10);
  border-top: 1px solid var(--color__border);
}

.ds__title {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 1.625rem;
  line-height: 1.875rem;
  font-weight: 700;
  letter-spacing: 0.01em;
  margin-bottom: var(--spacing-06) !important;
}
.ds__title b,
.ds__title strong {
  font-weight: 600;
}
.ds__title i,
.ds__title em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .ds__title {
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: 700;
    letter-spacing: 0.01em;
  }
}

.ds__pre {
  display: inline-flex;
  padding: 0 var(--spacing-03);
  font-size: 12px;
  font-family: "SFMono-Regular", "Consolas", "Liberation Mono", "Menlo", "Courier", monospace;
  background-color: #ececec;
}

/* Section */
.ds__section-header {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: var(--spacing-14) 0;
  color: var(--color__text--on-dark);
  background-color: var(--color__background--dark);
}

.ds__section-content {
  padding: var(--spacing-11) 0;
}

.ds__section-title {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 3.75rem;
  line-height: 4.25rem;
  font-weight: 700;
  letter-spacing: 0;
}
.ds__section-title b,
.ds__section-title strong {
  font-weight: 600;
}
.ds__section-title i,
.ds__section-title em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .ds__section-title {
    font-size: 5rem;
    line-height: 5.5rem;
    font-weight: 700;
    letter-spacing: 0;
  }
}

.ds__section-intro {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  margin-top: var(--spacing-04);
}
.ds__section-intro b,
.ds__section-intro strong {
  font-weight: 600;
}
.ds__section-intro i,
.ds__section-intro em {
  font-style: italic;
}
@media screen and (min-width: 1024px) {
  .ds__section-intro {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 400;
  }
}

.ds__section-subtitle {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 1.625rem;
  line-height: 1.875rem;
  font-weight: 700;
  letter-spacing: 0.01em;
  margin-bottom: var(--spacing-05);
}
.ds__section-subtitle b,
.ds__section-subtitle strong {
  font-weight: 600;
}
.ds__section-subtitle i,
.ds__section-subtitle em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .ds__section-subtitle {
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: 700;
    letter-spacing: 0.01em;
  }
}

/* Table */
.ds__table {
  table-layout: fixed;
  width: 100%;
  background-color: var(--color__background);
}
.ds__table tr {
  border-bottom: 1px solid #F2F2F2;
}
.ds__table th,
.ds__table td {
  padding: var(--spacing-03) var(--spacing-05);
  vertical-align: top;
}
.ds__table th {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-align: left;
}
.ds__table th b,
.ds__table th strong {
  font-weight: 700;
}
.ds__table th i,
.ds__table th em {
  font-style: italic;
}
.ds__table td {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.ds__table td b,
.ds__table td strong {
  font-weight: 600;
}
.ds__table td i,
.ds__table td em {
  font-style: italic;
}

/* Spacing */
.ds__spacing {
  display: block;
  background-color: #0088FF;
}
.ds__spacing--01 {
  width: var(--spacing-01);
  height: var(--spacing-01);
}
.ds__spacing--02 {
  width: var(--spacing-02);
  height: var(--spacing-02);
}
.ds__spacing--03 {
  width: var(--spacing-03);
  height: var(--spacing-03);
}
.ds__spacing--04 {
  width: var(--spacing-04);
  height: var(--spacing-04);
}
.ds__spacing--05 {
  width: var(--spacing-05);
  height: var(--spacing-05);
}
.ds__spacing--06 {
  width: var(--spacing-06);
  height: var(--spacing-06);
}
.ds__spacing--07 {
  width: var(--spacing-07);
  height: var(--spacing-07);
}
.ds__spacing--08 {
  width: var(--spacing-08);
  height: var(--spacing-08);
}
.ds__spacing--09 {
  width: var(--spacing-09);
  height: var(--spacing-09);
}
.ds__spacing--10 {
  width: var(--spacing-10);
  height: var(--spacing-10);
}
.ds__spacing--11 {
  width: var(--spacing-11);
  height: var(--spacing-11);
}
.ds__spacing--12 {
  width: var(--spacing-12);
  height: var(--spacing-12);
}
.ds__spacing--13 {
  width: var(--spacing-13);
  height: var(--spacing-13);
}
.ds__spacing--14 {
  width: var(--spacing-14);
  height: var(--spacing-14);
}
.ds__spacing--15 {
  width: var(--spacing-15);
  height: var(--spacing-15);
}
.ds__spacing--16 {
  width: var(--spacing-16);
  height: var(--spacing-16);
}
.ds__spacing--17 {
  width: var(--spacing-17);
  height: var(--spacing-17);
}
.ds__spacing--18 {
  width: var(--spacing-18);
  height: var(--spacing-18);
}
.ds__spacing--19 {
  width: var(--spacing-19);
  height: var(--spacing-19);
}

/* Colors */
.ds-colors__list {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: calc(var(--spacing-06) * -1);
}
.ds-colors__list + .ds-colors__list {
  margin-top: var(--spacing-06);
}

.ds-colors__item {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  position: relative;
  width: 250px;
  height: 125px;
  margin-right: var(--spacing-06);
  margin-bottom: var(--spacing-06);
  padding: 34px 16px 0;
  border: 1px solid #e6e6e6;
  /* Shades */
  /* Usages */
}
.ds-colors__item b,
.ds-colors__item strong {
  font-weight: 600;
}
.ds-colors__item i,
.ds-colors__item em {
  font-style: italic;
}
.ds-colors__item--white {
  background-color: var(--color__white);
}
.ds-colors__item--black {
  background-color: var(--color__black);
}
.ds-colors__item--black .ds-colors__hexa {
  color: var(--color__text--on-dark);
}
.ds-colors__item--black-54 {
  background-color: var(--color__black--54);
}
.ds-colors__item--black-54 .ds-colors__hexa {
  color: var(--color__text--on-dark);
}
.ds-colors__item--black-20 {
  background-color: var(--color__black--20);
}
.ds-colors__item--black-10 {
  background-color: var(--color__black--10);
}
.ds-colors__item--black-05 {
  background-color: var(--color__black--05);
}
.ds-colors__item--black-03 {
  background-color: var(--color__black--03);
}
.ds-colors__item--black-a-75 {
  background-color: var(--color__black--a-75);
}
.ds-colors__item--black-a-75 .ds-colors__hexa {
  color: var(--color__text--on-dark);
}
.ds-colors__item--blue {
  background-color: var(--color__blue);
}
.ds-colors__item--blue .ds-colors__hexa {
  color: var(--color__text--on-dark);
}
.ds-colors__item--yellow {
  background-color: var(--color__yellow);
}
.ds-colors__item--background {
  background-color: var(--color__background);
}
.ds-colors__item--background-dark {
  background-color: var(--color__background--dark);
}
.ds-colors__item--background-dark .ds-colors__hexa {
  color: var(--color__text--on-dark);
}
.ds-colors__item--background-light {
  background-color: var(--color__background--light);
}
.ds-colors__item--background-ultra-light {
  background-color: var(--color__background--ultra-light);
}
.ds-colors__item--text {
  background-color: var(--color__text);
}
.ds-colors__item--text .ds-colors__hexa {
  color: var(--color__text--on-dark);
}
.ds-colors__item--text-on-dark {
  background-color: var(--color__text--on-dark);
}
.ds-colors__item--text-light {
  background-color: var(--color__text--light);
}
.ds-colors__item--text-light .ds-colors__hexa {
  color: var(--color__text--on-dark);
}
.ds-colors__item--text-highlight {
  background-color: var(--color__text--highlight);
}
.ds-colors__item--text-placeholder {
  background-color: var(--color__text--placeholder);
}
.ds-colors__item--overlay {
  background-color: var(--color__overlay);
}
.ds-colors__item--overlay .ds-colors__hexa {
  color: var(--color__text--on-dark);
}
.ds-colors__item--overlay-light {
  background-color: var(--color__overlay--light);
}
.ds-colors__item--border {
  background-color: var(--color__border);
}
.ds-colors__item--brand {
  background-color: var(--color__brand);
}
.ds-colors__item--brand .ds-colors__hexa {
  color: var(--color__text--on-dark);
}

.ds-colors__code {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  font-size: 12px;
  padding: var(--spacing-02) var(--spacing-04);
  background-color: var(--color__background);
  border-top: 1px solid #e6e6e6;
  z-index: 2;
}

/* Typo */
.ds-typo__item + .ds-typo__item {
  margin-top: var(--spacing-11);
}

h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
dl {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-top: 0;
}
h1 b,
h1 strong,
h2 b,
h2 strong,
h3 b,
h3 strong,
h4 b,
h4 strong,
h5 b,
h5 strong,
p b,
p strong,
ul b,
ul strong,
ol b,
ol strong,
dl b,
dl strong {
  font-weight: 600;
}
h1 i,
h1 em,
h2 i,
h2 em,
h3 i,
h3 em,
h4 i,
h4 em,
h5 i,
h5 em,
p i,
p em,
ul i,
ul em,
ol i,
ol em,
dl i,
dl em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
dl {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
}

b, strong {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}
b b,
b strong, strong b,
strong strong {
  font-weight: 600;
}
b i,
b em, strong i,
strong em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  b, strong {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
  }
}

h1,
h2,
h3 {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}
h1 b,
h1 strong,
h2 b,
h2 strong,
h3 b,
h3 strong {
  font-weight: 600;
}
h1 i,
h1 em,
h2 i,
h2 em,
h3 i,
h3 em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  h1,
h2,
h3 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
  }
}

ul li {
  list-style-type: none;
}

img {
  border: 0 none;
}

a {
  color: inherit;
  text-decoration: none;
}

.js-hide {
  display: none;
}

.js-show {
  display: block;
}

.icon {
  display: inline-block;
}

.icon svg {
  display: inherit;
  width: 100%;
  height: 100%;
  vertical-align: inherit;
}

.tag {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-flex;
  padding: var(--spacing-01) var(--spacing-03);
  color: var(--color__text);
  white-space: nowrap;
  overflow: visible;
  background-color: var(--color__background--light);
  border-radius: 999px;
}
.tag b,
.tag strong {
  font-weight: 600;
}
.tag i,
.tag em {
  font-style: italic;
}

.tag--greyed {
  padding: 0;
  background-color: transparent;
}

.btn {
  display: block;
  min-width: 100px;
  padding-top: var(--spacing-03);
  padding-bottom: var(--spacing-03);
  border: 0;
  border-radius: 20px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.25s;
  -webkit-appearance: none;
}

@media screen and (max-width: 599px) {
  .hide--xsmall {
    display: none !important;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .hide--small {
    display: none !important;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .hide--medium {
    display: none !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .hide--large {
    display: none !important;
  }
}
@media screen and (min-width: 1280px) {
  .hide--xlarge {
    display: none !important;
  }
}
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.headerToggle {
  background-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  border: 0 none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: inherit;
  border-radius: 0;
  position: fixed;
  right: 0;
  top: 0;
  display: block;
  height: 64px;
  padding-right: 24px;
  padding-left: 24px;
  color: inherit;
  line-height: 64px;
  z-index: 14;
}
@media screen and (min-width: 600px) {
  .headerToggle {
    height: 72px;
    line-height: 72px;
  }
}
@media screen and (min-width: 770px) {
  .headerToggle {
    display: none;
  }
}

.block-copy {
  max-width: 680px;
  margin-top: var(--spacing-13);
}
.block-copy .block-copy__list {
  margin-top: 0;
  padding-left: 0;
}
.block-copy .block-copy__list .block-copy__variation-item {
  margin-top: 0;
  cursor: pointer;
}
.block-copy .block-copy__list .block-copy__variation-item[data-blockCopy-variation-selected] {
  color: var(--color__text);
  background-color: var(--color__background--ultra-light);
}

.block-copy__header {
  display: flex;
  position: relative;
  align-items: center;
  padding: var(--spacing-05);
  background-color: var(--color__background--ultra-light);
  z-index: 2;
}
@media screen and (max-width: 769px) {
  .block-copy__header {
    align-items: flex-start;
  }
}

.block-copy__title {
  margin-right: auto;
}

.block-copy__body {
  position: relative;
  padding: var(--spacing-05);
  border: var(--spacing-02) solid var(--color__background--ultra-light);
}
.block-copy__body .block-copy__variation {
  display: none;
}
.block-copy__body .block-copy__variation[data-blockCopy-variation-body-selected] {
  display: block;
}
.block-copy__body .block-copy__variation p {
  margin-top: 0;
}
.block-copy__body .block-copy__variation p + p {
  margin-top: var(--spacing-10);
}

.block-copy__target {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
}

.block-copy-dropdown {
  position: relative;
  margin-left: var(--spacing-05);
}

.block-copy__variation-select {
  padding-right: var(--spacing-02);
  border-right: 1px solid var(--color__background--medium);
}

.block-copy-dropdown__content {
  position: absolute;
}

.block-copy__label,
.block-copy__label-icon {
  display: flex;
  align-items: center;
}

.block-copy__label {
  background-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  border: 0 none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: inherit;
  border-radius: 0;
}

.block-copy__btn {
  background-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  border: 0 none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: inherit;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: var(--spacing-02);
  transition: color 300ms ease-out;
}
.block-copy__btn:hover {
  color: var(--color__text--light);
}

.block-copy__alert {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--color__text--on-dark);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: var(--spacing-05);
  text-align: center;
  background-color: var(--color__background--dark);
  z-index: 99;
  transform: translateY(100%);
  transition: transform 300ms ease-out;
}
.block-copy__alert b,
.block-copy__alert strong {
  font-weight: 600;
}
.block-copy__alert i,
.block-copy__alert em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .block-copy__alert {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
}
.block-copy__alert[data-blockCopy-success-reveal] {
  transform: translateY(0);
}

.navbarMenu {
  background-color: inherit;
}
@media screen and (min-width: 1024px) {
  .navbarMenu {
    margin-top: 72px;
  }
  .page-search .navbarMenu {
    margin-top: 0;
  }
}

.navbarMenu__pages {
  margin: 0;
  padding: var(--spacing-05) var(--spacing-06) var(--spacing-14) var(--spacing-06);
  counter-reset: chapter;
  /* Modifiers */
}
@media screen and (max-width: 769px) {
  .navbarMenu__pages {
    padding-right: var(--spacing-05);
    padding-left: var(--spacing-05);
  }
}
.navbarMenu__pages a {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  position: relative;
  display: flex;
  margin-top: var(--spacing-04);
}
.navbarMenu__pages a b,
.navbarMenu__pages a strong {
  font-weight: 600;
}
.navbarMenu__pages a i,
.navbarMenu__pages a em {
  font-style: italic;
}
.navbarMenu__pages a mark {
  color: inherit;
}
.navbarMenu__pages a:active,
.navbarMenu__pages a:hover {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  outline-width: 0;
  border-color: transparent;
  color: var(--color__text--light);
}
.navbarMenu__pages a:active b,
.navbarMenu__pages a:active strong,
.navbarMenu__pages a:hover b,
.navbarMenu__pages a:hover strong {
  font-weight: 600;
}
.navbarMenu__pages a:active i,
.navbarMenu__pages a:active em,
.navbarMenu__pages a:hover i,
.navbarMenu__pages a:hover em {
  font-style: italic;
}
.navbarMenu__pages a.theme-link {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}
.navbarMenu__pages a.theme-link b,
.navbarMenu__pages a.theme-link strong {
  font-weight: 700;
}
.navbarMenu__pages a.theme-link i,
.navbarMenu__pages a.theme-link em {
  font-style: italic;
}
.navbarMenu__pages > li {
  margin-top: var(--spacing-06);
}
.navbarMenu__pages > li:first-child {
  margin-top: 0;
}
.navbarMenu__pages .link--no-accessible {
  opacity: 0.2;
  pointer-events: none;
}
.navbarMenu__pages .navbarMenu__context {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  display: block;
  color: var(--color__text--light);
}
.navbarMenu__pages .navbarMenu__context b,
.navbarMenu__pages .navbarMenu__context strong {
  font-weight: 600;
}
.navbarMenu__pages .navbarMenu__context i,
.navbarMenu__pages .navbarMenu__context em {
  font-style: italic;
}
.navbarMenu__pages--home > li > a {
  padding-left: 0;
}
.navbarMenu__pages--home > li > a::before {
  display: none;
}

.navbarMenu__subpages {
  max-height: 0;
  margin-bottom: calc(var(--spacing-3) * -1);
  visibility: hidden;
  overflow: hidden;
  transition: max-height 0.25s linear, padding 0.25s linear, visibility 0s 0.25s;
}

a[aria-expanded=true] + {
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
}
a[aria-expanded=true] + .navbarMenu__subpages {
  max-height: 162px;
  visibility: visible;
  transition: max-height 0.25s linear, padding 0.25s linear;
}
a[aria-expanded=true] + .navbarMenu__subpages--0 {
  max-height: 162px;
}
a[aria-expanded=true] + .navbarMenu__subpages--1 {
  max-height: 312px;
}
a[aria-expanded=true] + .navbarMenu__subpages--2 {
  max-height: 462px;
}
a[aria-expanded=true] + .navbarMenu__subpages--3 {
  max-height: 612px;
}
a[aria-expanded=true] + .navbarMenu__subpages--4 {
  max-height: 762px;
}
a[aria-expanded=true] + .navbarMenu__subpages--5 {
  max-height: 912px;
}
a[aria-expanded=true] + .navbarMenu__subpages--6 {
  max-height: 1062px;
}
a[aria-expanded=true] + .navbarMenu__subpages--7 {
  max-height: 1212px;
}
a[aria-expanded=true] + .navbarMenu__subpages--8 {
  max-height: 1362px;
}
a[aria-expanded=true] + .navbarMenu__subpages--9 {
  max-height: 1512px;
}
a[aria-expanded=true] + .navbarMenu__subpages--10 {
  max-height: 1662px;
}
a[aria-expanded=true] + .navbarMenu__subpages--11 {
  max-height: 1812px;
}
a[aria-expanded=true] + .navbarMenu__subpages--12 {
  max-height: 1962px;
}
a[aria-expanded=true] + .navbarMenu__subpages--13 {
  max-height: 2112px;
}
a[aria-expanded=true] + .navbarMenu__subpages--14 {
  max-height: 2262px;
}
a[aria-expanded=true] + .navbarMenu__subpages--15 {
  max-height: 2412px;
}
a[aria-expanded=true] + .navbarMenu__subpages--16 {
  max-height: 2562px;
}
a[aria-expanded=true] + .navbarMenu__subpages--17 {
  max-height: 2712px;
}
a[aria-expanded=true] + .navbarMenu__subpages--18 {
  max-height: 2862px;
}
a[aria-expanded=true] + .navbarMenu__subpages--19 {
  max-height: 3012px;
}
a[aria-expanded=true] + .navbarMenu__subpages--20 {
  max-height: 3162px;
}

/* Table of content opened */
.navbarMenu__pages--toc {
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
  /* works with up to 3 * 20 pages per section which should be enough */
}
.navbarMenu__pages--toc .navbarMenu__subpages {
  max-height: 162px;
  padding-bottom: var(--spacing-03);
  visibility: visible;
  transition: max-height 0.25s linear, padding 0.25s linear;
}
.navbarMenu__pages--toc .navbarMenu__subpages--0 {
  max-height: 162px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--1 {
  max-height: 312px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--2 {
  max-height: 462px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--3 {
  max-height: 612px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--4 {
  max-height: 762px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--5 {
  max-height: 912px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--6 {
  max-height: 1062px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--7 {
  max-height: 1212px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--8 {
  max-height: 1362px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--9 {
  max-height: 1512px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--10 {
  max-height: 1662px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--11 {
  max-height: 1812px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--12 {
  max-height: 1962px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--13 {
  max-height: 2112px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--14 {
  max-height: 2262px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--15 {
  max-height: 2412px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--16 {
  max-height: 2562px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--17 {
  max-height: 2712px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--18 {
  max-height: 2862px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--19 {
  max-height: 3012px;
}
.navbarMenu__pages--toc .navbarMenu__subpages--20 {
  max-height: 3162px;
}

.navbarMenu__pages ul {
  padding-left: var(--spacing-06);
}

.navbarMenu__guides {
  position: relative;
  background-color: inherit;
  z-index: 8;
}
.navbarMenu__guides ul li:last-child {
  margin-bottom: var(--spacing-06);
}
.navbarMenu__guides button + ul {
  top: 64px;
}
@media screen and (min-width: 1024px) {
  .navbarMenu__guides button + ul {
    top: 72px;
  }
}
@media screen and (max-width: 1023px) {
  .navbarMenu__guides {
    position: sticky;
    top: 0;
    padding-left: var(--spacing-05);
    padding-right: var(--spacing-05);
  }
}
@media screen and (min-width: 1024px) {
  .navbarMenu__guides {
    margin: 0 var(--spacing-06);
  }
}

.navbarMenu__toggle {
  background-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  border: 0 none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: inherit;
  border-radius: 0;
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  position: relative;
  box-sizing: border-box;
  padding-top: 14px;
  padding-right: var(--spacing-06);
  padding-bottom: 14px;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  transition: color 0.3s ease;
}
.navbarMenu__toggle b,
.navbarMenu__toggle strong {
  font-weight: 700;
}
.navbarMenu__toggle i,
.navbarMenu__toggle em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .navbarMenu__toggle {
    padding-top: var(--spacing-05);
    padding-bottom: calc(var(--spacing-05) - 1px);
  }
}
.navbarMenu__guides--with-subpages .navbarMenu__toggle {
  padding-right: var(--spacing-10);
}
.navbarMenu__toggle:not(span) {
  cursor: pointer;
}
.navbarMenu__toggle:not(span):hover {
  color: var(--color__text--light);
}
@media screen and (max-width: 769px) {
  .navbarMenu__toggle {
    padding-right: var(--spacing-10);
  }
  .navbarMenu__guides--with-subpages .navbarMenu__toggle {
    padding-right: var(--spacing-13);
  }
}
@media screen and (min-width: 1024px) {
  .navbarMenu__toggle {
    padding-top: var(--spacing-06);
    padding-bottom: calc(var(--spacing-06) - 1px);
  }
}

.navbarMenu__close {
  background-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  border: 0 none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: inherit;
  border-radius: 0;
  position: absolute;
  top: 14px;
  right: var(--spacing-06);
  z-index: 2;
}
@media screen and (max-width: 769px) {
  .navbarMenu__close {
    right: var(--spacing-05);
  }
}
@media screen and (min-width: 600px) {
  .navbarMenu__close {
    top: var(--spacing-05);
  }
}
@media screen and (min-width: 1024px) {
  .navbarMenu__close {
    display: none;
  }
}

.navbarMenu__toggleIcon,
.navbarMenu__tocIcon {
  position: absolute;
  right: 0;
  top: 14px;
  margin-left: var(--spacing-02);
}
@media screen and (min-width: 600px) {
  .navbarMenu__toggleIcon,
.navbarMenu__tocIcon {
    top: var(--spacing-05);
  }
}
@media screen and (max-width: 1023px) {
  .navbarMenu__toggleIcon,
.navbarMenu__tocIcon {
    right: var(--spacing-10);
  }
}
@media screen and (min-width: 1024px) {
  .navbarMenu__toggleIcon,
.navbarMenu__tocIcon {
    top: var(--spacing-06);
  }
}

.navbarMenu__toggleIcon {
  top: -1px;
  transition: transform 0.25s linear;
  transform: rotate(0);
}

.navbar--expanded .navbarMenu__toggleIcon,
.navbarMenu__guides--active .navbarMenu__toggleIcon {
  transform: rotate(180deg);
}

.navbarMenu__guides li a,
.navbarMenu__toggle {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  display: block;
  width: 100%;
  text-align: left;
}
.navbarMenu__guides li a b,
.navbarMenu__guides li a strong,
.navbarMenu__toggle b,
.navbarMenu__toggle strong {
  font-weight: 600;
}
.navbarMenu__guides li a i,
.navbarMenu__guides li a em,
.navbarMenu__toggle i,
.navbarMenu__toggle em {
  font-style: italic;
}

.navbarMenu__guides li a {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-top: var(--spacing-06);
}
.navbarMenu__guides li a b,
.navbarMenu__guides li a strong {
  font-weight: 600;
}
.navbarMenu__guides li a i,
.navbarMenu__guides li a em {
  font-style: italic;
}

.navbarMenu__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: var(--color__overlay);
  opacity: 0;
  visibility: hidden;
  z-index: 7;
  cursor: pointer;
  transition: opacity 0.35s ease, visibility 0s 0.35s;
}

/* No numbers in post search results */
.searchResults .navbarMenu__pages > li > a {
  display: block;
  padding-left: 0;
}
.searchResults .navbarMenu__pages > li > a:before {
  display: none;
}

.media {
  position: relative;
  margin-top: var(--spacing-12);
}
.media img {
  display: block;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  line-height: 10em;
  color: var(--color__text--light);
  text-align: center;
}

.media--full {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .media--full {
    padding-left: 250px;
  }
}

.media--bordered .media__img,
.media--bordered .post__video {
  position: relative;
}
.media--bordered .media__img::after,
.media--bordered .post__video::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background: transparent;
  border: 1px solid var(--color__border);
  pointer-events: none;
  border-radius: inherit;
}

.media__img {
  background-size: cover;
  background-position: center;
  position: relative;
  height: 0;
  background-color: var(--color__border);
  overflow: hidden;
  border-radius: 20px;
}
.media__img.js--lazyloaded {
  background-color: transparent !important;
  background-image: none !important;
}
.media__img img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

/* SVGvariant */
.media__svg {
  background-color: var(--color__border);
  overflow: hidden;
}
.media__svg .media__outer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 67.3913043478%;
  min-height: 20vw;
  margin: 16.3043478261%;
}
@media screen and (min-width: 1024px) {
  .media__svg .media__outer {
    min-height: 320px;
  }
}
.media__svg img {
  max-width: 100%;
}

.media__img img {
  opacity: 1;
  transition: opacity 0.25s;
}

.media__img img[data-src]:not([src^="data:image"]) {
  opacity: 0;
}

.media__img img[data-srcset]:not([src^="data:image"]) {
  opacity: 0;
}

.media__caption {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin-top: var(--spacing-03);
  color: var(--color__text--light);
}
.media__caption b,
.media__caption strong {
  font-weight: 600;
}
.media__caption i,
.media__caption em {
  font-style: italic;
}

/* Zoom */
.media[data-behavior=zoom] {
  z-index: 1;
}

.media[data-behavior=zoom] img {
  cursor: zoom-in;
}

.media[data-behavior=zoom].media--zooming {
  z-index: 20;
}

.media[data-behavior=zoom].media--zoomed img {
  visibility: hidden;
}
.media[data-behavior=zoom].media--zoomed .media__caption {
  visibility: hidden;
}

.media[data-behavior=zoom]:before {
  content: "";
  position: fixed;
  top: -100vh;
  left: -100vw;
  width: 100%;
  height: 100%;
  padding: 100vh 100vw;
  background: rgba(255, 255, 255, 0.97);
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  transform: translateZ(0);
  pointer-events: none;
  transition: opacity 0.3s ease, visibility 0s 0.3s;
}

.media.media--medium {
  max-width: 680px;
}

.media[data-behavior=zoom].media--willzoom:before {
  display: block;
  pointer-events: auto;
}

.media[data-behavior=zoom].media--zooming:before {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease;
}

.media[data-behavior=zoom]::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  font-size: 5px;
  margin-top: -15px;
  margin-left: -15px;
  text-indent: -9999em;
  opacity: 0;
  border-top: 2px solid rgba(100, 100, 100, 0.33);
  border-right: 2px solid rgba(100, 100, 100, 0.33);
  border-bottom: 2px solid rgba(100, 100, 100, 0.33);
  border-left: 2px solid rgba(100, 100, 100, 0.8);
  border-radius: 50%;
  overflow: hidden;
  transform: translateZ(0);
  animation: rotatingSpinner 1.1s infinite linear;
  transition: opacity 0.1s;
}

.media[data-behavior=zoom].media--loading::after {
  opacity: 1;
}

.media[data-behavior=zoom].media--loading img {
  opacity: 0.5;
}

.media__clone {
  position: absolute;
  top: 80px;
  bottom: 80px;
  left: 80px;
  background-position: center center;
  background-size: cover;
  visibility: hidden;
  will-change: transform;
  cursor: zoom-out;
  z-index: 21;
  transition: transform 0.3s;
}

@keyframes rotatingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.media__title {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  margin-top: var(--spacing-05);
}
.media__title b,
.media__title strong {
  font-weight: 600;
}
.media__title i,
.media__title em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .media__title {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
  }
}

.media__desc {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-top: var(--spacing-01);
}
.media__desc b,
.media__desc strong {
  font-weight: 600;
}
.media__desc i,
.media__desc em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .media__desc {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
}

.media + .media__desc {
  margin-top: var(--spacing-04);
}

.cover {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 400px;
  max-height: 800px;
  margin-top: var(--spacing-13);
  margin-left: var(--spacing-06);
  margin-right: var(--spacing-06);
  border-radius: 20px;
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .cover {
    min-height: 600px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .cover {
    min-height: 800px;
  }
}
@media screen and (min-width: 1024px) {
  .cover {
    min-height: 800px;
  }
}
@media screen and (min-width: 600px) {
  .cover {
    margin-top: var(--spacing-14);
  }
}
.cover img {
  display: block;
  position: absolute;
  left: 50%;
  right: 0;
  top: 50%;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-height: 800px;
  margin: 0;
  object-position: 50% 50%;
  object-fit: cover;
  transform: translateX(-50%) translateY(-50%);
}

.postContent .post:first-child > .cover {
  margin-top: 0;
}
@media screen and (min-width: 600px) {
  .postContent .post:first-child > .cover {
    margin-top: 0;
  }
}

.cover__img {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--color__border);
}
.cover__img.js--lazyloaded {
  background-color: transparent !important;
  background-image: none !important;
}

.cover__img img {
  opacity: 1;
  transition: opacity 0.25s;
}

.cover__img img[data-src]:not([src^="data:image"]) {
  opacity: 0;
}

.cover__img img[data-srcset]:not([src^="data:image"]) {
  opacity: 0;
}

.post-grid {
  display: flex;
  flex-flow: row wrap;
}
@media screen and (max-width: 599px) {
  .post-grid {
    margin-left: -20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .post-grid {
    margin-left: -20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .post-grid {
    margin-left: -40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .post-grid {
    margin-left: -40px;
  }
}
@media screen and (min-width: 1280px) {
  .post-grid {
    margin-left: -40px;
  }
}

.post-grid__item {
  flex: 0 0 auto;
  position: relative;
}
@media screen and (max-width: 599px) {
  .post-grid__item {
    width: calc(100vw - 40px - 0px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .post-grid__item {
    width: calc(100vw - 80px - 0px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .post-grid__item {
    width: calc((((100vw - 0px - 520px) / 12) * 6) + 200px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .post-grid__item {
    width: calc((((100vw - 250px - 550px) / 12) * 6) + 200px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .post-grid__item {
    width: 440px;
    margin-left: 40px;
  }
}

.post-grid__item-media {
  flex: 0 0 auto;
}
@media screen and (max-width: 599px) {
  .post-grid__item-media {
    width: calc(100vw - 40px - 0px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .post-grid__item-media {
    width: calc((((100vw - 0px - 180px) / 6) * 3) + 40px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .post-grid__item-media {
    width: calc((((100vw - 0px - 520px) / 12) * 6) + 200px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .post-grid__item-media {
    width: calc((((100vw - 250px - 550px) / 12) * 6) + 200px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .post-grid__item-media {
    width: 440px;
    margin-left: 40px;
  }
}

.post-grid .media {
  margin-top: var(--spacing-10);
}
.post-grid .post-grid__item:nth-child(n+3) .media {
  margin-top: var(--spacing-13);
}

.post-small-grid {
  display: flex;
  flex-flow: row wrap;
}
@media screen and (max-width: 599px) {
  .post-small-grid {
    margin-left: -20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .post-small-grid {
    margin-left: -20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .post-small-grid {
    margin-left: -40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .post-small-grid {
    margin-left: -40px;
  }
}
@media screen and (min-width: 1280px) {
  .post-small-grid {
    margin-left: -40px;
  }
}

.post-small-grid__item {
  flex: 0 0 auto;
  position: relative;
}
@media screen and (max-width: 599px) {
  .post-small-grid__item {
    width: calc(100vw - 40px - 0px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .post-small-grid__item {
    width: calc(100vw - 80px - 0px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .post-small-grid__item {
    width: calc((((100vw - 0px - 520px) / 12) * 6) + 200px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .post-small-grid__item {
    width: calc((((100vw - 250px - 550px) / 12) * 4) + 120px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .post-small-grid__item {
    width: 280px;
    margin-left: 40px;
  }
}

.post-small-grid__item-media {
  flex: 0 0 auto;
}
@media screen and (max-width: 599px) {
  .post-small-grid__item-media {
    width: calc(100vw - 40px - 0px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .post-small-grid__item-media {
    width: calc((((100vw - 0px - 180px) / 6) * 2) + 20px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .post-small-grid__item-media {
    width: calc((((100vw - 0px - 520px) / 12) * 4) + 120px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .post-small-grid__item-media {
    width: calc((((100vw - 250px - 550px) / 12) * 4) + 120px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .post-small-grid__item-media {
    width: 280px;
    margin-left: 40px;
  }
}

.post-small-grid .media {
  margin-top: var(--spacing-10);
}
.post-small-grid .post-small-grid__item:nth-child(n+4) .media {
  margin-top: var(--spacing-13);
}

.color {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  margin-top: 50px;
  position: relative;
  min-height: 200px;
}
.color b,
.color strong {
  font-weight: 600;
}
.color i,
.color em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .color {
    margin-top: 60px;
    padding-bottom: 0;
  }
}

.color__info {
  padding: 20px;
  position: absolute;
  top: 0;
}
@media screen and (min-width: 600px) {
  .color__info {
    padding: 30px;
  }
}

.color + .color {
  margin-top: 35px;
}
@media screen and (min-width: 600px) {
  .color + .color {
    margin-top: 45px;
  }
}

.color--dark {
  color: var(--color__background);
}

.color dl {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
}
.color dl b,
.color dl strong {
  font-weight: 600;
}
.color dl i,
.color dl em {
  font-style: italic;
}

.color dt {
  margin-right: var(--spacing-03);
}

.color dd {
  flex-grow: 1;
}

.color h1, .color h2, .color h3, .color h4, .color h5 {
  margin: 0;
}

.brand-colors-grid {
  display: flex;
  flex-flow: row wrap;
}
@media screen and (max-width: 599px) {
  .brand-colors-grid {
    margin-left: -20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .brand-colors-grid {
    margin-left: -20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .brand-colors-grid {
    margin-left: -40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .brand-colors-grid {
    margin-left: -40px;
  }
}
@media screen and (min-width: 1280px) {
  .brand-colors-grid {
    margin-left: -40px;
  }
}

.brand-colors-grid .brand-colors {
  flex: 0 0 auto;
}
@media screen and (max-width: 599px) {
  .brand-colors-grid .brand-colors {
    width: calc(100vw - 40px - 0px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .brand-colors-grid .brand-colors {
    width: calc(100vw - 80px - 0px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .brand-colors-grid .brand-colors {
    width: calc((((100vw - 0px - 520px) / 12) * 6) + 200px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .brand-colors-grid .brand-colors {
    width: calc((((100vw - 250px - 550px) / 12) * 4) + 120px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .brand-colors-grid .brand-colors {
    width: 280px;
    margin-left: 40px;
  }
}

.brand-colors {
  border-radius: 20px;
  overflow: hidden;
  margin-top: 50px;
}
@media screen and (min-width: 600px) {
  .brand-colors {
    margin-top: 60px;
    padding-bottom: 0;
  }
}

.brand-color {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.brand-color__info {
  flex-grow: 1;
  padding-left: var(--spacing-05);
  padding-right: var(--spacing-05);
  text-transform: uppercase;
}

.brand-color--dark {
  color: var(--color__background);
}

.brand-color dl {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.brand-color dl b,
.brand-color dl strong {
  font-weight: 600;
}
.brand-color dl i,
.brand-color dl em {
  font-style: italic;
}

.brand-color dd {
  text-align: right;
}

.hubHeader {
  padding-bottom: var(--spacing-13);
}

.hubHeader--branded {
  position: relative;
  padding-bottom: var(--spacing-17);
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

.hubHeader--image {
  min-height: 400px;
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .hubHeader--image {
    min-height: 440px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .hubHeader--image {
    min-height: 600px;
  }
}
@media screen and (min-width: 1024px) {
  .hubHeader--image {
    min-height: 600px;
  }
}

.hubHeader__img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.hubHeader__img img {
  height: 100%;
  display: block;
  margin: 0 auto;
  margin-left: 50vw;
  transform: translateX(-50%);
}
.hubHeader__img + .content {
  position: relative;
  z-index: 1;
}

.objectFit .hubHeader__img img {
  width: 100%;
  height: 100%;
  margin: 0;
  object-position: 50% 50%;
  object-fit: cover;
  transform: none;
}

.hubHeader--light {
  color: var(--color__background);
  background: black;
}

.hubHeader__inner {
  display: flex;
  flex-flow: row wrap;
}
@media screen and (max-width: 599px) {
  .hubHeader__inner {
    margin-left: -20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .hubHeader__inner {
    margin-left: -20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .hubHeader__inner {
    margin-left: -30px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .hubHeader__inner {
    margin-left: -40px;
  }
}
@media screen and (min-width: 1280px) {
  .hubHeader__inner {
    margin-left: -40px;
  }
}
.hubHeader__inner > * {
  flex: 0 0 auto;
}
@media screen and (max-width: 599px) {
  .hubHeader__inner > * {
    width: calc(100vw - 40px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .hubHeader__inner > * {
    width: calc(100vw - 80px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .hubHeader__inner > * {
    width: calc((((100vw - 410px) / 12) * 6) + 150px);
    margin-left: 30px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .hubHeader__inner > * {
    width: calc((((100vw - 560px) / 12) * 6) + 200px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .hubHeader__inner > * {
    width: 560px;
    margin-left: 40px;
  }
}
.hubHeader__inner .f-heading-01 {
  margin-top: var(--spacing-13);
}
.hubHeader__inner .f-heading-01::after {
  content: "";
  mix-blend-mode: difference;
}
.hubHeader__inner .f-intro {
  margin-top: var(--spacing-13);
}

.hubIntro {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: var(--spacing-13);
}
@media screen and (max-width: 599px) {
  .hubIntro {
    margin-left: -20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .hubIntro {
    margin-left: -20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .hubIntro {
    margin-left: -30px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .hubIntro {
    margin-left: -40px;
  }
}
@media screen and (min-width: 1280px) {
  .hubIntro {
    margin-left: -40px;
  }
}
@media screen and (min-width: 600px) {
  .hubIntro {
    margin-bottom: var(--spacing-15);
  }
}
.hubIntro > * {
  flex: 0 0 auto;
  margin-top: var(--spacing-12);
}
@media screen and (max-width: 599px) {
  .hubIntro > * {
    width: calc(100vw - 40px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .hubIntro > * {
    width: calc(100vw - 80px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .hubIntro > * {
    width: calc((((100vw - 410px) / 12) * 6) + 150px);
    margin-left: 30px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .hubIntro > * {
    width: calc((((100vw - 560px) / 12) * 6) + 200px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .hubIntro > * {
    width: 560px;
    margin-left: 40px;
  }
}

.hubHeader--branded + .content .hubIntro > * {
  margin-top: var(--spacing-12);
}

.hub__title {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--color__text--light);
  text-transform: uppercase;
}
.hub__title b,
.hub__title strong {
  font-weight: 600;
}
.hub__title i,
.hub__title em {
  font-style: italic;
}

.hub {
  display: flex;
  flex-flow: row wrap;
  margin-top: var(--spacing-10);
}
@media screen and (max-width: 599px) {
  .hub {
    margin-left: -20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .hub {
    margin-left: -20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .hub {
    margin-left: -30px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .hub {
    margin-left: -40px;
  }
}
@media screen and (min-width: 1280px) {
  .hub {
    margin-left: -40px;
  }
}
@media screen and (min-width: 600px) {
  .hub {
    margin-top: var(--spacing-13);
  }
}

.hubCollection {
  margin-top: var(--spacing-10);
}
@media screen and (min-width: 600px) {
  .hubCollection {
    margin-top: var(--spacing-13);
  }
}

.hub__title + .hub {
  margin-top: var(--spacing-05);
}
@media screen and (min-width: 600px) {
  .hub__title + .hub {
    margin-top: var(--spacing-08);
  }
}

.hub__item {
  flex: 0 0 auto;
  position: relative;
  margin-bottom: var(--spacing-05);
  border-radius: 20px;
  border: 1px solid var(--color__background--dark);
  overflow: hidden;
}
@media screen and (max-width: 599px) {
  .hub__item {
    width: calc(100vw - 40px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .hub__item {
    width: calc((((100vw - 180px) / 6) * 3) + 40px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .hub__item {
    width: calc((((100vw - 410px) / 12) * 6) + 150px);
    margin-left: 30px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .hub__item {
    width: calc((((100vw - 560px) / 12) * 4) + 120px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .hub__item {
    width: 360px;
    margin-left: 40px;
  }
}
.hub__item .hub__bg {
  position: relative;
  display: block;
  padding-bottom: 127.7777777778%;
  background: var(--color__background);
}
@media screen and (max-width: 599px) {
  .hub__item {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .hub__item {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .hub__item {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .hub__item {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .hub__item {
    margin-bottom: 40px;
  }
}

.featured {
  display: flex;
  flex-flow: row wrap;
  margin-top: var(--spacing-10);
}
@media screen and (max-width: 599px) {
  .featured {
    margin-left: -20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .featured {
    margin-left: -20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .featured {
    margin-left: -30px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .featured {
    margin-left: -40px;
  }
}
@media screen and (min-width: 1280px) {
  .featured {
    margin-left: -40px;
  }
}
@media screen and (min-width: 600px) {
  .featured {
    margin-top: var(--spacing-13);
  }
}

.featured__item {
  flex: 0 0 auto;
  position: relative;
  margin-bottom: var(--spacing-10);
}
@media screen and (max-width: 599px) {
  .featured__item {
    width: calc(100vw - 40px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .featured__item {
    width: calc((((100vw - 180px) / 6) * 3) + 40px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .featured__item {
    width: calc((((100vw - 410px) / 12) * 6) + 150px);
    margin-left: 30px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .featured__item {
    width: calc((((100vw - 560px) / 12) * 6) + 200px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .featured__item {
    width: 560px;
    margin-left: 40px;
  }
}
.featured__item a {
  display: block;
  height: 100%;
  background: var(--color__background);
  border-radius: 20px;
}

.featured__media img {
  display: block;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  line-height: 10em;
  color: var(--color__text--light);
  text-align: center;
}

.featured__mediaWrapper {
  height: 0;
  padding-bottom: 67.8571428571%;
  background-color: var(--color__border);
}

.featured__item--primary {
  flex: 0 0 auto;
}
@media screen and (max-width: 599px) {
  .featured__item--primary {
    width: calc(100vw - 40px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .featured__item--primary {
    width: calc(100vw - 80px);
    margin-left: 20px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .featured__item--primary {
    width: calc(100vw - 80px);
    margin-left: 30px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .featured__item--primary {
    width: calc(100vw - 120px);
    margin-left: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .featured__item--primary {
    width: 1160px;
    margin-left: 40px;
  }
}
.featured__item--primary a {
  display: block;
}
@media screen and (min-width: 600px) {
  .featured__item--primary a {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
  }
  .featured__item--primary a > * {
    width: 50%;
  }
}
.featured__item--primary .featured__mediaWrapper {
  padding-bottom: 100%;
}

.card {
  padding: var(--spacing-07) var(--spacing-06);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 60px;
  display: block;
  width: 100%;
  color: var(--color__text);
  overflow: hidden;
  transition: color 0.3s ease;
}
.card::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 20px;
}
.card .card__desc, .card .card__desc p {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-top: var(--spacing-05);
}
.card .card__desc b,
.card .card__desc strong, .card .card__desc p b,
.card .card__desc p strong {
  font-weight: 600;
}
.card .card__desc i,
.card .card__desc em, .card .card__desc p i,
.card .card__desc p em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .card .card__desc, .card .card__desc p {
    padding-right: 5%;
  }
}

.card--disabled {
  opacity: 0.2;
}
.card--disabled + .card__footer {
  opacity: 0.2;
}

.card--featured {
  position: relative;
  height: auto;
  padding-bottom: 130px;
}

.card__footer {
  position: absolute;
  left: var(--spacing-06);
  bottom: var(--spacing-06);
  right: var(--spacing-06);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.card__collections {
  flex-grow: 1;
}

.files {
  max-width: 680px;
  margin-top: var(--spacing-08);
  border: 1px solid var(--color__border);
  border-radius: 20px;
  overflow: hidden;
}
@media screen and (min-width: 600px) {
  .files {
    margin-top: var(--spacing-11);
  }
}

.files__item + .files__item {
  border-top: 1px solid var(--color__border);
}

.files__link {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--spacing-04) var(--spacing-08) var(--spacing-04) 56px;
}
.files__link .icon--download {
  position: absolute;
  display: block;
  top: var(--spacing-04);
  left: var(--spacing-05);
}
.files__link:hover {
  background: var(--color__background--ultra-light);
}

.files__name {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  flex: 1;
  padding-right: var(--spacing-06);
}
.files__name b,
.files__name strong {
  font-weight: 600;
}
.files__name i,
.files__name em {
  font-style: italic;
}

.files__size {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--color__text--light);
  text-transform: uppercase;
}
.files__size b,
.files__size strong {
  font-weight: 600;
}
.files__size i,
.files__size em {
  font-style: italic;
}
@media screen and (max-width: 599px) {
  .files__size {
    display: block;
    width: 100%;
  }
}

.dropdown {
  position: relative;
}
.dropdown > a {
  color: var(--color__text);
  transition: color 0.35s ease;
}

.dropdown__list {
  position: absolute;
  top: calc(100% + var(--spacing-02));
  right: 0;
  display: none;
  min-width: 200px;
  max-width: calc(100vw - var(--spacing-03));
  padding: var(--spacing-03) 0;
  background: var(--color__background);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.dropdown__list a,
.dropdown__list .dropdown__item {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  display: block;
  height: var(--spacing-10);
  padding: 0 var(--spacing-08) 0 var(--spacing-05);
  line-height: var(--spacing-10);
  color: var(--color__text--light);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.35s ease;
}
.dropdown__list a b,
.dropdown__list a strong,
.dropdown__list .dropdown__item b,
.dropdown__list .dropdown__item strong {
  font-weight: 600;
}
.dropdown__list a i,
.dropdown__list a em,
.dropdown__list .dropdown__item i,
.dropdown__list .dropdown__item em {
  font-style: italic;
}
.dropdown__list a:hover,
.dropdown__list .dropdown__item:hover {
  color: var(--color__text);
  background-color: var(--color__background--ultra-light);
}

.dropdown > a:hover,
.dropdown--active > a {
  color: var(--color__text--light);
}
.dropdown > a:hover .icon,
.dropdown--active > a .icon {
  color: var(--color__text--light);
}

.dropdown--active .dropdown__list {
  display: block;
}

.dropdown__sep {
  position: relative;
  margin-bottom: var(--spacing-05);
}

.dropdown__sep::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  display: block;
  height: 1px;
  width: 100%;
  background: var(--color__border);
}

/* spacing vertical & horizontal */
.userInfo {
  box-sizing: border-box;
  padding: 12px 0 12px 20px;
  text-align: left;
  overflow: visible;
}
.userInfo .userInfo__dropdown > a {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.userInfo .icon--dropdown-small {
  margin-right: calc(var(--spacing-01) * -1);
}

@media screen and (max-width: 599px) {
  .userInfo__name {
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.panel {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateY(0);
}

.panel__content {
  bottom: 0;
  background-color: var(--color__background);
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  z-index: 40;
}

.panel__wrapper {
  position: relative;
  padding-top: var(--spacing-08);
  overflow: hidden;
}

.panel__header {
  height: 64px;
  text-align: center;
  background-color: var(--color__background);
  background-clip: padding-box;
  border-bottom: 1px solid var(--color__border);
  cursor: pointer;
  z-index: 41;
}
@media screen and (min-width: 1024px) {
  .panel__header {
    height: 72px;
  }
}

.panel__close {
  -webkit-appearance: none;
  box-sizing: border-box;
  display: inline-block;
  height: 40px;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: 40px;
  color: var(--color__text);
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  cursor: pointer;
  transition: color 0.35s ease, border-color 0.35s ease;
}
.panel__close .icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.panel__close:hover {
  color: var(--color__text);
  border-color: var(--color__text);
}
.panel__close:active {
  outline: 0;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: var(--color__background--error);
  color: var(--color__text--on-dark);
}

.error__title {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 3.75rem;
  line-height: 4.25rem;
  font-weight: 700;
  letter-spacing: 0;
}
.error__title b,
.error__title strong {
  font-weight: 600;
}
.error__title i,
.error__title em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .error__title {
    font-size: 5rem;
    line-height: 5.5rem;
    font-weight: 700;
    letter-spacing: 0;
  }
}

.error__desc {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  padding: 0 var(--spacing-05);
  margin-top: var(--spacing-05);
  text-align: center;
}
.error__desc b,
.error__desc strong {
  font-weight: 600;
}
.error__desc i,
.error__desc em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .error__desc {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
}
@media screen and (min-width: 770px) {
  .error__desc {
    margin-top: var(--spacing-10);
  }
}

[data-behavior=softVideo] {
  display: block;
  transition: opacity 250ms ease-in-out;
  max-width: 100%;
  width: 100%;
  border-radius: 20px;
}
[data-behavior=softVideo]:not(.s-fade-in), [data-behavior=softVideo].s-hidden {
  opacity: 0;
}
[data-behavior=softVideo].s-fade-in:not(.s-hidden) {
  opacity: 1;
}

[data-behavior=toggleVideo], [data-behavior=toggleVideoSound] {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  left: auto;
  border-radius: 100%;
  background: var(--color__background--ultra-light);
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  color: var(--color__background--dark);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms ease;
  cursor: pointer;
}
[data-behavior=toggleVideo] svg, [data-behavior=toggleVideoSound] svg {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
}
[data-behavior=toggleVideo]:hover, [data-behavior=toggleVideoSound]:hover {
  background: var(--color__background);
}

[data-behavior=toggleVideoSound] {
  right: 4rem;
}

.vimeo {
  padding-bottom: 56.25%;
  position: relative;
}
.vimeo iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.post {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  position: relative;
}
.post b,
.post strong {
  font-weight: 600;
}
.post i,
.post em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .post {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
}

.post__wrapper {
  margin-right: auto;
  margin-left: auto;
}
.post__wrapper::before {
  content: " ";
  position: relative;
  height: 0px;
  width: 100%;
  overflow: hidden;
  white-space: pre;
  display: block;
}
@media screen and (max-width: 599px) {
  .post__wrapper {
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .post__wrapper {
    width: auto;
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .post__wrapper {
    width: auto;
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .post__wrapper {
    width: auto;
    padding-right: 55px;
    padding-left: 55px;
  }
}
@media screen and (min-width: 1280px) {
  .post__wrapper {
    width: 920px;
    padding-right: 0;
    padding-left: 0;
  }
}

.post__separator {
  height: 1px;
  margin: var(--spacing-13) auto -1px;
  padding: 0;
  background-color: var(--color__border);
  border: 0 none;
}
@media screen and (max-width: 599px) {
  .post__separator {
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .post__separator {
    width: auto;
    margin-right: 40px;
    margin-left: 40px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .post__separator {
    width: auto;
    margin-right: 40px;
    margin-left: 40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .post__separator {
    width: auto;
    margin-right: 55px;
    margin-left: 55px;
  }
}
@media screen and (min-width: 1280px) {
  .post__separator {
    width: 920px;
  }
}

.post__anchor {
  /* margin-top:$header-h - 1px; */
  display: block;
  height: 60px;
  width: 100%;
  position: absolute;
  top: -32px;
  z-index: 0;
  pointer-events: none;
  visibility: hidden;
}
@media screen and (min-width: 600px) {
  .post__anchor {
    top: -36px;
  }
}

.cover ~ .post__anchor {
  top: -64px;
}
@media screen and (min-width: 600px) {
  .cover ~ .post__anchor {
    top: -72px;
  }
}

.post__collections {
  margin-top: var(--spacing-10);
}

/* dont display the separator when there is a cover */
.cover ~ .post__separator {
  display: none;
}

/* Post - Headings */
.post h1 {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 3.75rem;
  line-height: 4.25rem;
  font-weight: 700;
  letter-spacing: 0;
  max-width: 680px;
  margin-top: var(--spacing-13);
}
.post h1 b,
.post h1 strong {
  font-weight: 600;
}
.post h1 i,
.post h1 em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .post h1 {
    font-size: 5rem;
    line-height: 5.5rem;
    font-weight: 700;
    letter-spacing: 0;
  }
}

.post h2 {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 2rem;
  line-height: 2.25rem;
  font-weight: 700;
  letter-spacing: 0.01em;
  max-width: 680px;
  margin-top: var(--spacing-15);
}
.post h2 b,
.post h2 strong {
  font-weight: 600;
}
.post h2 i,
.post h2 em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .post h2 {
    font-size: 3.75rem;
    line-height: 4.25rem;
    font-weight: 700;
    letter-spacing: 0;
  }
}

.main {
  counter-reset: titleNumber;
}

/* Numbers before titles */
.post h2 a span {
  text-decoration: none;
  background-image: linear-gradient(to bottom, var(--color__text--light) 75%, var(--color__text--light) 75%);
  background-repeat: repeat-x;
  background-size: 1px 1px;
  background-position: 0 98%;
}
.post h2 a span:hover {
  background-image: linear-gradient(to bottom, var(--color__text--light) 75%, var(--color__text--light) 75%);
}

.post h3 {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 1.625rem;
  line-height: 1.875rem;
  font-weight: 700;
  letter-spacing: 0.01em;
  max-width: 680px;
  margin-top: var(--spacing-15);
}
.post h3 b,
.post h3 strong {
  font-weight: 600;
}
.post h3 i,
.post h3 em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .post h3 {
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: 700;
    letter-spacing: 0.01em;
  }
}

.post h4 {
  font-family: "WaldenburgBoldSemiCondensed", "Arial", sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  max-width: 680px;
  margin-top: var(--spacing-14);
}
.post h4 b,
.post h4 strong {
  font-weight: 600;
}
.post h4 i,
.post h4 em {
  font-style: italic;
}
@media screen and (min-width: 770px) {
  .post h4 {
    font-size: 1.625rem;
    line-height: 1.875rem;
    font-weight: 700;
    letter-spacing: 0.01em;
  }
}

.post h5 {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  max-width: 680px;
  margin-top: var(--spacing-13);
}
.post h5 b,
.post h5 strong {
  font-weight: 600;
}
.post h5 i,
.post h5 em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .post h5 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
  }
}

/* Post - Intro */
.post .intro {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
}
.post .intro b,
.post .intro strong {
  font-weight: 600;
}
.post .intro i,
.post .intro em {
  font-style: italic;
}
@media screen and (min-width: 1024px) {
  .post .intro {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 400;
  }
}

.post__context {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  display: block;
  margin-top: var(--spacing-05);
  color: var(--color__text--light);
  white-space: nowrap;
}
.post__context b,
.post__context strong {
  font-weight: 600;
}
.post__context i,
.post__context em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .post__context {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
}
.post__context a {
  text-decoration: none;
  background-image: linear-gradient(to bottom, var(--color__text--light) 75%, var(--color__text--light) 75%);
  background-repeat: repeat-x;
  background-size: 1px 1px;
  background-position: 0 98%;
  transition: color 0.35s ease;
}
.post__context a:hover {
  background-image: linear-gradient(to bottom, var(--color__text) 75%, var(--color__text) 75%);
}
.post__context a:hover {
  color: var(--color__text);
}

/* Post - Text */
.post__text,
.post .intro {
  max-width: 680px;
  word-break: break-word;
}

.post__text img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  line-height: 10em;
  color: var(--color__text--light);
  text-align: center;
  background-color: var(--color__border);
}

/*
  Low level container of blocks :
  Texts and grids
*/
.post-small-grid,
.post-grid,
.post__text {
  margin-top: var(--spacing-05);
}

.post .intro {
  margin-top: var(--spacing-10);
}

.post p {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-top: var(--spacing-05);
}
.post p b,
.post p strong {
  font-weight: 600;
}
.post p i,
.post p em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .post p {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
}

.post .intro p:first-child {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  margin-top: 0;
}
.post .intro p:first-child b,
.post .intro p:first-child strong {
  font-weight: 600;
}
.post .intro p:first-child i,
.post .intro p:first-child em {
  font-style: italic;
}
@media screen and (min-width: 1024px) {
  .post .intro p:first-child {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 400;
  }
}

.post pre {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-top: var(--spacing-13);
  font-size: 0.85rem;
  line-height: 1.2rem;
  font-weight: 400;
  padding: var(--spacing-06);
}
.post pre b,
.post pre strong {
  font-weight: 600;
}
.post pre i,
.post pre em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .post pre {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
}

.post ol li,
.post ul li {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-top: var(--spacing-04);
}
.post ol li b,
.post ol li strong,
.post ul li b,
.post ul li strong {
  font-weight: 600;
}
.post ol li i,
.post ol li em,
.post ul li i,
.post ul li em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .post ol li,
.post ul li {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
}

/* Post links / underlines in texts */
.post u {
  text-decoration: none;
  background-image: linear-gradient(to bottom, var(--color__text) 75%, var(--color__text) 75%);
  background-repeat: repeat-x;
  background-size: 1px 1px;
  background-position: 0 98%;
}
.post ul a,
.post ol a,
.post p a {
  text-decoration: none;
  background-image: linear-gradient(to bottom, var(--color__text) 75%, var(--color__text) 75%);
  background-repeat: repeat-x;
  background-size: 1px 1px;
  background-position: 0 98%;
  text-decoration: none;
  transition: color 0.35s ease;
}
.post ul a:hover,
.post ol a:hover,
.post p a:hover {
  background-image: linear-gradient(to bottom, var(--color__text--light) 75%, var(--color__text--light) 75%);
}
.post ul a:hover,
.post ol a:hover,
.post p a:hover {
  color: var(--color__text--light);
}

/* Post - Ordered Lists */
.post ol,
.post ul {
  margin-top: var(--spacing-10);
}
.post ol li,
.post ul li {
  list-style-type: inherit;
}

.post ul {
  padding-left: 24px;
}
.post ul li {
  position: relative;
}
.post ul li:before {
  position: absolute;
  font-size: 0.66em;
  left: -24px;
  content: "●";
}
.post ul ul li:before {
  content: "○";
}
.post ul ul li li:before {
  content: "◆";
}
.post ul ul li li li:before {
  content: "◇";
}

/* Post - Ordered Lists from Quill.js */
.post ul > li.ql-indent-1 {
  margin-left: 24px;
}
.post ul > li.ql-indent-1:before {
  content: "○";
}

.post ul > li.ql-indent-2 {
  margin-left: 48px;
}
.post ul > li.ql-indent-2:before {
  content: "◆";
}

.post ul > li.ql-indent-3 {
  margin-left: 72px;
}
.post ul > li.ql-indent-3:before {
  content: "◇";
}

.post ul > li.ql-indent-4 {
  margin-left: 72px;
}
.post ul > li.ql-indent-4:before {
  content: "◇";
}

/* Numbered lists */
.post ol {
  counter-reset: listNumber;
  padding-left: 24px;
}
.post ol li {
  position: relative;
  counter-reset: listNumber-2 listNumber-3 listNumber-4;
}
.post ol li:before {
  position: absolute;
  font-size: 1em;
  left: -24px;
  content: counter(listNumber) " ";
  counter-increment: listNumber;
}
.post ol ol {
  counter-reset: listNumber-2;
  padding-left: 34px;
}
.post ol ol li {
  counter-reset: listNumber-3 listNumber-4;
}
.post ol ol li:before {
  left: -34px;
  content: counter(listNumber) "." counter(listNumber-2) " ";
  counter-increment: listNumber-2;
}
.post ol ol ol {
  counter-reset: listNumber-3;
  padding-left: 44px;
}
.post ol ol ol li {
  counter-reset: listNumber-4;
}
.post ol ol ol li:before {
  left: -44px;
  content: counter(listNumber) "." counter(listNumber-2) "." counter(listNumber-3) " ";
  counter-increment: listNumber-3;
}
.post ol ol ol ol {
  counter-reset: listNumber-4;
  padding-left: 54px;
}
.post ol ol ol ol li {
  counter-reset: listNumber-5;
}
.post ol ol ol ol li:before {
  left: -54px;
  content: counter(listNumber) "." counter(listNumber-2) "." counter(listNumber-3) "." counter(listNumber-4) " ";
  counter-increment: listNumber-4;
}

/* Post - Numbered Lists from Quill.js */
.post ol li {
  counter-reset: listNumber-2 listNumber-3 listNumber-4;
}
.post ol li:before {
  left: -24px;
  content: counter(listNumber) " ";
  counter-increment: listNumber;
}

.post ol li.ql-indent-1 {
  margin-left: 34px;
  counter-reset: listNumber-3 listNumber-4;
}
.post ol li.ql-indent-1:before {
  counter-increment: listNumber-2;
  left: -34px;
  content: counter(listNumber) "." counter(listNumber-2) " ";
  counter-increment: listNumber-2;
}

.post ol li.ql-indent-2 {
  margin-left: 68px;
  counter-reset: listNumber-4;
}
.post ol li.ql-indent-2:before {
  left: -44px;
  content: counter(listNumber) "." counter(listNumber-2) "." counter(listNumber-3) " ";
  counter-increment: listNumber-3;
}

.post ol li.ql-indent-3 {
  margin-left: 102px;
  counter-reset: listNumber-5;
}
.post ol li.ql-indent-3:before {
  counter-increment: listNumber-4;
  left: -54px;
  content: counter(listNumber) "." counter(listNumber-2) "." counter(listNumber-3) "." counter(listNumber-4) " ";
  counter-increment: listNumber-4;
}

/* Italic */
.post i,
.post em {
  font-style: italic;
}

/* Bold */
.post b,
.post strong {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}
.post b b,
.post b strong,
.post strong b,
.post strong strong {
  font-weight: 600;
}
.post b i,
.post b em,
.post strong i,
.post strong em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .post b,
.post strong {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
  }
}

/* Quote */
.post blockquote {
  margin-top: var(--spacing-10);
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  font-style: italic;
}
.post blockquote b,
.post blockquote strong {
  font-weight: 600;
}
.post blockquote i,
.post blockquote em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .post blockquote {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
  }
}

/* mark for search */
.post mark {
  border-radius: 2px;
  background-color: var(--color__text--highlight);
  color: var(--color__text--on-dark);
}

/* Invisible mark */
.post .post__result mark,
.post .post__results mark,
.post h1 a mark,
.post h2 a mark,
.post h3 a mark {
  color: inherit;
  background-color: transparent;
}

/* code blocks */
.post pre {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.2rem;
  font-weight: 400;
  padding: var(--spacing-06);
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  color: var(--color__text--on-dark);
  background-color: var(--color__background--dark);
  border-radius: 3px;
  overflow: auto;
}
.post pre b,
.post pre strong {
  font-weight: 600;
}
.post pre i,
.post pre em {
  font-style: italic;
}

/* Search results */
.post__results {
  color: var(--color__text--light);
}
.post__results ol li,
.post__results ul li {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.post__results ol li b,
.post__results ol li strong,
.post__results ul li b,
.post__results ul li strong {
  font-weight: 600;
}
.post__results ol li i,
.post__results ol li em,
.post__results ul li i,
.post__results ul li em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .post__results ol li,
.post__results ul li {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
}
/* Margins rules */
.post .media + .post__text,
.post h2 + .post__text,
.post h3 + .post__text,
.post h4 + .post__text,
.post p + pre {
  margin-top: var(--spacing-05);
}

/* No numbers in post search results */
.searchResults .post h2:before {
  display: none;
}

/* Tables */
.post .post__table {
  display: block;
  width: 100%;
  overflow-x: auto;
  margin-top: var(--spacing-10);
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.post table {
  width: 100%;
  border-right: none;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  background: var(--color__background);
}
.post table tbody {
  width: 100%;
}
.post table th {
  background-color: var(--color__background--ultra-light);
}
.post table th p {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}
.post table th p b,
.post table th p strong {
  font-weight: 700;
}
.post table th p i,
.post table th p em {
  font-style: italic;
}
.post table tr {
  border-bottom: 1px solid var(--color__border);
}
.post table th,
.post table td {
  padding: var(--spacing-04) var(--spacing-04);
  text-align: left;
  min-width: 200px;
  width: 25%;
}
.post table th p,
.post table td p {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-top: 0;
}
.post table th p b,
.post table th p strong,
.post table td p b,
.post table td p strong {
  font-weight: 600;
}
.post table th p i,
.post table th p em,
.post table td p i,
.post table td p em {
  font-style: italic;
}
.post table tr:first-child th p,
.post table tr:first-child td p {
  font-weight: 500;
}

/* Videos */
.post .post__video,
.post .post__vimeo {
  margin-top: var(--spacing-10);
  position: relative;
  border-radius: 20px;
}
.post .post__video [data-behavior=toggleVideo], .post .post__video [data-behavior=toggleVideoSound],
.post .post__vimeo [data-behavior=toggleVideo],
.post .post__vimeo [data-behavior=toggleVideoSound] {
  display: flex;
  opacity: 0;
  transition: opacity 0.35s ease;
}
.post .post__video:hover [data-behavior=toggleVideo], .post .post__video:hover [data-behavior=toggleVideoSound],
.post .post__vimeo:hover [data-behavior=toggleVideo],
.post .post__vimeo:hover [data-behavior=toggleVideoSound] {
  opacity: 1;
}

html {
  /* juggling left/right and margin left/right as 100vw includes scroll bars so forcing 100vw width to make grid colspan calcs work */
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  min-height: 100%;
  margin-right: -50vw;
  margin-left: -50vw;
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color__background);
  color: var(--color__text);
  font-family: "Waldenburg", "Arial", sans-serif;
  font-feature-settings: "kern";
  font-kerning: normal;
  line-height: 1;
  text-rendering: optimizeLegibility;
  font-variant-ligatures: common-ligatures;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
body b,
body strong {
  font-weight: 600;
}
body i,
body em {
  font-style: italic;
}
body::after {
  position: absolute;
  left: -1px;
  top: -1px;
  width: 1px;
  height: 1px;
  margin-top: -1px;
  margin-left: -1px;
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
}

@media screen and (max-width: 599px) {
  head {
    font-family: "xsmall";
  }

  body::after {
    content: "xsmall";
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  head {
    font-family: "small";
  }

  body::after {
    content: "small";
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  head {
    font-family: "medium";
  }

  body::after {
    content: "medium";
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  head {
    font-family: "large";
  }

  body::after {
    content: "large";
  }
}
@media screen and (min-width: 1280px) {
  head {
    font-family: "xlarge";
  }

  body::after {
    content: "xlarge";
  }
}
#a17 {
  position: relative;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 51px;
}
@media screen and (min-width: 600px) {
  .main {
    padding-top: 63px;
  }
}
@media screen and (min-width: 1024px) {
  .main {
    padding-top: 71px;
  }
}
.main > * {
  padding-top: 1px;
}

.main--preview {
  margin-top: 0;
  padding-top: 0;
}
@media screen and (min-width: 600px) {
  .main--preview {
    margin-top: 0;
    padding-top: 0;
  }
}
.main--preview > * {
  margin-top: 0;
  padding-top: 0;
}

.main--hub {
  background: var(--color__background);
}
.main--hub + .footer {
  background: var(--color__background);
}

.content {
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 599px) {
  .content {
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .content {
    width: auto;
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1023px) {
  .content {
    width: auto;
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .content {
    width: auto;
    padding-right: 60px;
    padding-left: 60px;
  }
}
@media screen and (min-width: 1280px) {
  .content {
    width: 1160px;
    padding-right: 0;
    padding-left: 0;
  }
}

.postContent {
  width: 100%;
  padding-bottom: var(--spacing-17);
}
@media screen and (min-width: 1024px) {
  .postContent {
    padding-bottom: 260px;
  }
}
@media screen and (min-width: 1024px) {
  .postContent {
    padding-left: 250px;
  }
}
@media screen and (min-width: 1280px) {
  .postContent {
    padding-left: 19.53125%;
  }
}
@media screen and (min-width: 2048px) {
  .postContent {
    /* 2048 * (250 / 1280) = 450px so this match navbar max-width value */
    padding-left: 400px;
  }
}
.postContent .post:first-child {
  /* dont display the separator on the very first post */
}
.postContent .post:first-child .post__separator {
  display: none;
}

/* When previewing blocks in the Admin */
.postContent--preview {
  padding-bottom: var(--spacing-01);
}
@media screen and (min-width: 1024px) {
  .postContent--preview {
    padding-left: 0;
  }
}

.navbar {
  position: fixed;
  top: 0;
  bottom: 0;
  display: block;
  width: 100%;
  right: -100%;
  background: var(--color__background);
  background-clip: padding-box;
  visibility: hidden;
  z-index: 14;
  transform: translateZ(0);
  transform-style: preserve-3d;
  transition: opacity 0.35s ease, right 0.35s ease, visibility 0s 0.35s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.navbar.navbar--active {
  right: 0;
  visibility: visible;
  transition: opacity 0.35s ease, right 0.35s;
}
@media screen and (min-width: 600px) {
  .navbar {
    width: 376px;
    right: -376px;
  }
}
@media screen and (min-width: 1024px) {
  .navbar {
    left: 0;
    right: auto;
    width: 19.53125%;
    min-width: 250px;
    max-width: 400px;
    visibility: visible;
    z-index: 8;
    transition: opacity 0.35s ease, left 0.35s;
  }
}

.navbar__overlay {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background: var(--color__overlay);
  opacity: 0;
  visibility: hidden;
  z-index: 13;
  cursor: pointer;
  transition: opacity 0.35s ease, visibility 0s 0.35s, height 0s 0.35s;
}
@media screen and (min-width: 1024px) {
  .navbar__overlay {
    display: none;
  }
}

.navbar--active + .navbar__overlay {
  height: 100%;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.35s;
}

.navbar__wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
}

.navbar__wrapper > div {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
  padding-top: 0;
  padding-bottom: var(--spacing-05);
  background-color: inherit;
}

.navbar__copy {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  padding: 0 var(--spacing-06) var(--spacing-06) var(--spacing-06);
  color: var(--color__text--light);
}
.navbar__copy b,
.navbar__copy strong {
  font-weight: 600;
}
.navbar__copy i,
.navbar__copy em {
  font-style: italic;
}

@media screen and (min-width: 1024px) {
  .navbar--home,
.navbar--search {
    left: -250px;
    visibility: hidden;
  }
  .navbar--home.navbar--active,
.navbar--search.navbar--active {
    left: -250px;
    visibility: hidden;
  }
  .navbar--home.navbar--active + .navbar__overlay,
.navbar--search.navbar--active + .navbar__overlay {
    opacity: 0;
    visibility: hidden;
  }
}

/* Search results specific UI */
.navbar--searchResults .navbarMenu__toggleIcon {
  display: none;
}
@media screen and (max-width: 1023px) {
  .navbar--searchResults {
    position: fixed;
    top: 63px;
    left: 0;
    right: 0;
    bottom: auto;
    width: 100%;
    max-height: calc(100% - 64px);
    background-color: transparent !important;
    border-right: 0 none;
    visibility: visible;
    z-index: 12;
  }
  .navbar--searchResults .navbar__wrapper {
    position: static;
    overflow: hidden;
  }
  .navbar--searchResults .navbar__wrapper > div {
    padding: 0;
  }
  .navbar--searchResults .navbarMenu__guides {
    margin: 0;
    padding: 0;
  }
  .navbar--searchResults .navbarMenu__toggle {
    padding-left: 24px;
  }
  .navbar--searchResults .navbarMenu__toggle .navbarMenu__toggleIcon {
    top: var(--spacing-05);
  }
  .navbar--searchResults .navbarMenu__toggleIcon {
    display: block;
    right: var(--spacing-07);
  }
  .navbar--searchResults .navbarMenu__pagesContainer {
    height: calc(100vh - 72px - 60px);
    max-height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.35s ease, visibility 0s 0.35s, max-height 0s 0.35s;
  }
  .navbar--searchResults.navbar--expanded .navbarMenu__pagesContainer {
    max-height: calc(100vh - 72px - 60px);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transition: opacity 0.35s;
  }
}
@media screen and (max-width: 599px) {
  .navbar--searchResults {
    top: 63px;
    max-height: calc(100% - 64px);
  }
  .navbar--searchResults .navbarMenu__toggle {
    padding-left: 20px;
    padding-right: 48px;
  }
  .navbar--searchResults .navbarMenu__pagesContainer {
    height: calc(100vh - 64px - 60px);
    max-height: 0;
  }
  .navbar--searchResults.navbar--expanded .navbarMenu__pagesContainer {
    max-height: calc(100vh - 64px - 60px);
  }
}
@media screen and (min-width: 1024px) {
  .navbar--searchResults {
    top: 71px;
    max-height: calc(100% - 72px);
  }
}

/* spacing vertical & horizontal */
.header {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  background: var(--color__background);
  background-clip: padding-box;
  border-bottom: 1px solid var(--color__border);
  overflow: visible;
  z-index: 13;
}
@media screen and (max-width: 599px) {
  .header {
    max-height: var(--header-h-xsmall);
  }
}

.header__logo {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  max-width: 250px;
  padding: 6px 0 5px 20px;
  opacity: 1;
  z-index: 1;
  transition: opacity 0.35s ease;
}
@media screen and (min-width: 600px) {
  .header__logo {
    padding: 12px 0 11px 20px;
  }
}
@media screen and (min-width: 1024px) {
  .header__logo {
    height: 71px;
    padding: 16px 0 16px 24px;
  }
}

.header__right {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  padding-right: 20px;
}
@media screen and (min-width: 1024px) {
  .header__right {
    padding-right: 24px;
  }
}

.logo--desktop {
  display: none;
}
@media screen and (min-width: 1024px) {
  .logo--desktop {
    display: block;
  }
}

.logo--mobile {
  display: block;
}
@media screen and (min-width: 1024px) {
  .logo--mobile {
    display: none;
  }
}

.header__nav {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: center;
  overflow: visible;
}

.header__btn {
  -webkit-appearance: none;
  position: relative;
  display: inline-block;
  height: 40px;
  padding: 0;
  text-transform: uppercase;
  line-height: 40px;
  font-family: inherit;
  font-size: inherit;
  color: var(--color__text--light);
  background-color: transparent;
  border: 0 none;
  cursor: pointer;
  transition: color 0.35s ease;
}
.header__btn:hover {
  color: var(--color__text);
}
.header__btn:active {
  outline: 0;
}
.header__btn .icon {
  display: block;
}
@media screen and (max-width: 599px) {
  .header__btn {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .header__btn {
    height: 40px;
    line-height: 40px;
  }
}

.header__home {
  display: block;
  height: 40px;
  line-height: 39px;
}
@media screen and (min-width: 1024px) {
  .header__home {
    height: 40px;
    line-height: 39px;
  }
}

.header__home {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 599px) {
  .header__home {
    align-items: center;
  }
  .header__home .icon--logo {
    height: 18px;
    width: 125px;
  }
}

.header__toggle {
  background-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  border: 0 none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: inherit;
  border-radius: 0;
  position: relative;
  margin-left: var(--spacing-04);
  color: var(--color__text);
  transition: color 0.3s ease;
}
.header__toggle:hover {
  color: var(--color__text--light);
}
@media screen and (min-width: 1024px) {
  .header__toggle {
    display: none;
  }
}

.header__loader {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--color__text);
}

/* spacing vertical & horizontal */
.search {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color__background--ultra-light);
  background-clip: padding-box;
  z-index: 1;
}

.search__form {
  box-sizing: border-box;
  display: flex;
  padding: 6px 20px;
  height: 52px;
  overflow: hidden;
  box-shadow: inset 0px -1px 0px 0px transparent;
}
@media screen and (min-width: 600px) {
  .search__form {
    padding: 12px 20px;
    height: 64px;
  }
}
@media screen and (min-width: 1024px) {
  .search__form {
    height: 72px;
    padding: 16px 24px;
  }
}

.search__toggle {
  -webkit-appearance: none;
  display: block;
  margin: 0 0 0 var(--spacing-04);
  padding: 0;
  border: 0 none;
  font-family: inherit;
  font-size: inherit;
  color: var(--color__text);
  background-color: transparent;
  cursor: pointer;
  z-index: 2;
  transition: color 0.3s ease;
}
.search__toggle:hover {
  color: var(--color__text--light);
}
.search__toggle:active {
  outline: 0;
}
.search__toggle .icon--search {
  display: block;
}
.search__toggle .icon--close {
  display: none;
  transition: color 0.3s ease;
}
@media screen and (min-width: 1024px) {
  .search__toggle {
    margin: 0 0 0 var(--spacing-05);
  }
}

.search__toggle ~ ul {
  margin-right: 40px;
}
@media screen and (min-width: 600px) {
  .search__toggle ~ ul {
    margin-right: 44px;
  }
}

.search__submit {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  cursor: pointer;
}
.search__submit:active {
  outline: 0;
  box-shadow: none;
}

.search__submit,
.search__input {
  height: 100%;
}

.search__submit .icon {
  display: block;
}

.search__input {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  -webkit-appearance: none;
  display: block;
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: var(--spacing-03);
  font-family: inherit;
  color: var(--color__text);
  background-color: transparent;
  border: 0 none;
}
.search__input b,
.search__input strong {
  font-weight: 600;
}
.search__input i,
.search__input em {
  font-style: italic;
}
@media screen and (min-width: 1024px) {
  .search__input {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 400;
  }
}
.search__input::-webkit-search-decoration, .search__input::-webkit-search-cancel-button, .search__input::-webkit-search-results-button, .search__input::-webkit-search-results-decoration {
  display: none;
}
.search__input::-ms-clear {
  display: none;
}
.search__input::-ms-reveal {
  display: none;
}
.search__input:focus {
  outline: 0;
  box-shadow: none;
}
.search__input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.2);
}
.search__input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.2);
}
.search__input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.2);
}
.search__input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.2);
}
.search__input:focus, .search__input:hover {
  color: var(--color__text);
}

.search__autocomplete {
  background-clip: padding-box;
}
.search__autocomplete ul {
  padding: var(--spacing-05) 20px var(--spacing-05) 20px;
}
.search__autocomplete ul li {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
}
.search__autocomplete ul li b,
.search__autocomplete ul li strong {
  font-weight: 600;
}
.search__autocomplete ul li i,
.search__autocomplete ul li em {
  font-style: italic;
}
@media screen and (min-width: 1024px) {
  .search__autocomplete ul li {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 400;
  }
}
@media screen and (min-width: 600px) {
  .search__autocomplete ul {
    padding: var(--spacing-05) 24px;
  }
}

@media screen and (max-width: 1023px) {
  .searchResults .main {
    padding-top: 127px;
  }
  .searchResults .post__anchor {
    top: -95px;
  }
  .searchResults .cover ~ .post__anchor {
    top: -127px;
  }
}
.footer {
  margin-top: auto;
  padding-top: var(--spacing-14);
  padding-bottom: var(--spacing-06);
}
@media screen and (min-width: 600px) {
  .footer {
    display: flex;
    padding-bottom: var(--spacing-08);
  }
  .footer .footer__copyright {
    flex-grow: 1;
  }
}

.footer__copyright,
.footer__a17 {
  font-family: "Waldenburg", "Arial", sans-serif;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  padding: 0 var(--spacing-06);
  color: var(--color__text--light);
}
.footer__copyright b,
.footer__copyright strong,
.footer__a17 b,
.footer__a17 strong {
  font-weight: 600;
}
.footer__copyright i,
.footer__copyright em,
.footer__a17 i,
.footer__a17 em {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .footer__copyright,
.footer__a17 {
    padding: 0 var(--spacing-06);
  }
}

.footer__copyright li {
  padding-bottom: var(--spacing-02);
}
.footer__copyright li + li {
  padding-top: var(--spacing-10);
}
@media screen and (min-width: 600px) {
  .footer__copyright li + li {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 770px) {
  .footer__copyright {
    display: flex;
  }
  .footer__copyright li {
    flex-grow: 1;
    order: 1;
    padding-bottom: 0;
  }
  .footer__copyright li:first-of-type {
    padding-left: var(--spacing-06);
  }
  .footer__copyright li + li {
    order: 0;
    flex-grow: 0;
  }
}

.footer__a17 {
  margin-top: auto;
}
@media screen and (min-width: 600px) {
  .footer__a17 {
    padding-left: 0;
  }
}

.footer__link:hover,
a:hover .footer__link {
  text-decoration: none;
  background-image: linear-gradient(to bottom, var(--color__text--light) 75%, var(--color__text--light) 75%);
  background-repeat: repeat-x;
  background-size: 1px 1px;
  background-position: 0 98%;
}

/*
These classes, selectors will be overwrited per clients settings.
All classes are prefixed by 'theme-'
*/
/* Links Color */
.theme-link,
.theme-link--over {
  transition: color 0.35s ease;
}

.theme-link--overtag:hover {
  color: var(--color__background);
  background-color: var(--color__brand);
  transition: background-color 0.35s ease, color 0.35s ease;
}

.theme-link--border {
  border-color: var(--color__brand);
}

.theme-navbar {
  background-color: var(--color__background);
}
.theme-header {
  background-color: var(--color__background);
}
.page-index .theme-header, .page-collection .theme-header {
  background-color: var(--color__background);
  border-color: rgba(255, 255, 255, 0.15);
}
.page-index .theme-header .search__form, .page-collection .theme-header .search__form {
  box-shadow: inset 0px -1px 0px 0px var(--color__border);
}
.page-index .theme-header .header__logo,
.page-index .theme-header .dropdown > a,
.page-index .theme-header .search__toggle,
.page-index .theme-header .header__toggle, .page-collection .theme-header .header__logo,
.page-collection .theme-header .dropdown > a,
.page-collection .theme-header .search__toggle,
.page-collection .theme-header .header__toggle {
  color: var(--color__text);
}
.page-index .theme-header .header__logo .icon--close,
.page-index .theme-header .dropdown > a .icon--close,
.page-index .theme-header .search__toggle .icon--close,
.page-index .theme-header .header__toggle .icon--close, .page-collection .theme-header .header__logo .icon--close,
.page-collection .theme-header .dropdown > a .icon--close,
.page-collection .theme-header .search__toggle .icon--close,
.page-collection .theme-header .header__toggle .icon--close {
  color: var(--color__text);
}
.page-index .theme-header .header__logo:hover,
.page-index .theme-header .dropdown > a:hover,
.page-index .theme-header .search__toggle:hover,
.page-index .theme-header .header__toggle:hover, .page-collection .theme-header .header__logo:hover,
.page-collection .theme-header .dropdown > a:hover,
.page-collection .theme-header .search__toggle:hover,
.page-collection .theme-header .header__toggle:hover {
  color: var(--color__text--light);
}
.page-index .theme-header .header__logo:hover .icon--close,
.page-index .theme-header .dropdown > a:hover .icon--close,
.page-index .theme-header .search__toggle:hover .icon--close,
.page-index .theme-header .header__toggle:hover .icon--close, .page-collection .theme-header .header__logo:hover .icon--close,
.page-collection .theme-header .dropdown > a:hover .icon--close,
.page-collection .theme-header .search__toggle:hover .icon--close,
.page-collection .theme-header .header__toggle:hover .icon--close {
  color: var(--color__text--light);
}
.page-index .theme-header .header__logo:hover .icon--logo,
.page-index .theme-header .dropdown > a:hover .icon--logo,
.page-index .theme-header .search__toggle:hover .icon--logo,
.page-index .theme-header .header__toggle:hover .icon--logo, .page-collection .theme-header .header__logo:hover .icon--logo,
.page-collection .theme-header .dropdown > a:hover .icon--logo,
.page-collection .theme-header .search__toggle:hover .icon--logo,
.page-collection .theme-header .header__toggle:hover .icon--logo {
  color: var(--color__text);
}

.headerPost {
  opacity: 1;
  transition: opacity 0.3s linear;
}

.page-zoom .headerPost {
  opacity: 0;
}

.page-header {
  overflow: visible;
  height: 100%;
}

.page-header body main {
  position: fixed;
  width: 100%;
  overflow: hidden;
}
@media screen and (max-width: 599px) {
  .page-header body .search {
    opacity: 0;
    pointer-events: none;
  }
}

.main {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.theme-header,
.header__logo,
.dropdown > a,
.search__toggle,
.header__toggle {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbarHeader,
.header__inner,
.navbarMenu,
.header__btn {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.header__loader {
  width: 0;
  opacity: 0;
  transition: opacity 0.3s ease, width 3s ease;
}

.navbarLogo {
  transition: border-color 0.3s ease;
}

.pjax--loading .main {
  opacity: 0;
}
.pjax--loading .theme-header {
  color: var(--color__text) !important;
  background-color: var(--color__background);
  border-bottom: 1px solid var(--color__border);
}
.pjax--loading .header__logo,
.pjax--loading .dropdown > a,
.pjax--loading .search__toggle,
.pjax--loading .header__toggle {
  color: var(--color__text) !important;
}
.pjax--loading .header__loader {
  opacity: 0.5;
  width: 100%;
}
.pjax--loading .header__btn {
  opacity: 0;
}
.pjax--loading .navbar, .pjax--loading .navbar.navbar--active {
  left: -250px;
}
@media screen and (min-width: 1024px) {
  .pjax--loading .navbar, .pjax--loading .navbar.navbar--active {
    left: 0;
    opacity: 0;
  }
}
.pjax--loading .navbar__overlay,
.pjax--loading .navbarHeader,
.pjax--loading .header__inner,
.pjax--loading .navbarMenu {
  opacity: 0;
}
.pjax--loading .headerPost__nav,
.pjax--loading .headerPost__prevNext {
  opacity: 0;
}
.pjax--loading .navbarLogo {
  border-color: rgba(0, 0, 0, 0);
}

.search,
.searchResults {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.35s ease, visibility 0s 0.35s;
}

.search__toggle ~ ul {
  opacity: 1;
  transition: opacity 0.35s ease;
}

.header__overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 0;
  background: var(--color__background);
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  pointer-events: none;
  transition: opacity 0.35s ease, visibility 0s 0.35s, height 0s 0.35s;
}

.page-searching:not(.page-search) {
  overflow: hidden;
}
.page-searching:not(.page-search) body {
  height: 100%;
  overflow: hidden;
}
.page-searching:not(.page-search) .header__overlay {
  height: 100%;
  opacity: 0.95;
  visibility: visible;
  pointer-events: auto;
  transition: opacity 0.35s ease;
}

.page-searching .search,
.page-searching .searchResults {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition: opacity 0.35s;
}
.page-searching .search__toggle .icon--search {
  display: none;
}
.page-searching .search__toggle .icon--close {
  display: block;
}
.page-searching .search__toggle ~ ul {
  opacity: 0;
}
@media screen and (max-width: 1023px) {
  .page-searching .search__toggle {
    margin-right: calc(var(--spacing-10) * -1);
  }
}

.page-search .page {
  display: none;
}

.page-resultsExpanded {
  overflow: hidden;
}

.page-resultsExpanded body {
  overflow: hidden;
  height: 100%;
}

.page-autocomplete {
  overflow: hidden;
}
.page-autocomplete body {
  overflow: hidden;
  height: 100%;
}
.page-autocomplete .search__autocomplete {
  border-bottom: 1px solid var(--color__border);
}
.page-autocomplete .header__overlay {
  opacity: 0.95;
  transition: opacity 0.35s ease;
  visibility: visible;
  pointer-events: auto;
}

.panel {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0s 0.3s;
}

.page-panel {
  overflow: hidden;
}
.page-panel body {
  overflow: hidden;
  height: 100%;
}
.page-panel .panel {
  pointer-events: auto;
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}