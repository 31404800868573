.search,
.searchResults {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.35s ease, visibility 0s 0.35s;
}

.search__toggle ~ ul {
  opacity: 1;
  transition: opacity 0.35s ease;
}

.header__overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 0;
  background: var(--color__background);
  opacity: 0;
  visibility: hidden;
  z-index: $z-index-headerOverlay;
  pointer-events: none;
  transition: opacity 0.35s ease, visibility 0s 0.35s, height 0s 0.35s;
}

.page-searching:not(.page-search) {
  overflow: hidden;

  body {
    height: 100%;
    overflow: hidden;
  }

  .header__overlay {
    height: 100%;
    opacity: 0.95;
    visibility: visible;
    pointer-events: auto;
    transition: opacity 0.35s ease;
  }
}

.page-searching {
  .search,
  .searchResults {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transition: opacity 0.35s;
  }

  .search__toggle {
    .icon--search {
      display: none;
    }

    .icon--close {
      display: block;
    }
  }

  .search__toggle ~ ul {
    opacity: 0;
  }

  @include breakpoint('medium-') {
    .search__toggle {
      margin-right: calc(var(--spacing-10) * -1);
    }
  }
}

.page-search {
  .page {
    display: none;
  }
}
