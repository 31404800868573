.page-header {
    overflow: visible;
    height: 100%;
}

.page-header body {
    main {
        position:fixed;
        width: 100%;
        overflow: hidden;
    }

    @include breakpoint('xsmall') {
      .search {
        opacity:0;
        pointer-events: none;
      }
    }
}
