.headerToggle {
  @include btn-reset;
  position: fixed;
  right: 0;
  top: 0;
  display: block;
  height: $header-h-small;
  padding-right: $header-h-pad;
  padding-left: $header-h-pad;
  color: inherit;
  line-height: $header-h-small;
  z-index: $z-index-header + 1;

  @include breakpoint('small+') {
    height: $header-h;
    line-height: $header-h;
  }

  @include breakpoint('medium+') {
    display: none;
  }
}
