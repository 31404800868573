.postContent {
  width: 100%;
  padding-bottom: var(--spacing-17);

  @include breakpoint('large+') {
    padding-bottom: 260px;
  }

  @include breakpoint('large+') {
    padding-left: $navWidth;
  }

  @include breakpoint('xlarge') {
    padding-left: #{((250 / 1280) * 100%)};
  }

  @media screen and (min-width: 2048px) { /* 2048 * (250 / 1280) = 450px so this match navbar max-width value */
    padding-left: 400px;
  }

  .post:first-child {
    /* dont display the separator on the very first post */
    .post__separator {
      display: none;
    }
  }
}

/* When previewing blocks in the Admin */
.postContent--preview {
  padding-bottom: var(--spacing-01);

  @include breakpoint('large+') {
    padding-left: 0;
  }
}

// $margin-top__postCopyright:(
//   'xsmall' : 80,
//   'small+' : 120
// );

// .postCopyright {
//   color: var(--color__text--light);
//   @include smart-margin($f-h5, $margin-top__postCopyright);
// }
