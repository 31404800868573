.page-autocomplete {
  overflow: hidden;

  body {
    overflow: hidden;
    height: 100%;
  }

  .search__autocomplete {
    border-bottom: 1px solid var(--color__border);
  }

  .header__overlay {
    opacity:0.95;
    transition: opacity 0.35s ease;
    visibility: visible;
    pointer-events: auto;
  }
}
