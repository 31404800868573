.media {
  position: relative;
  margin-top: var(--spacing-12);

  img {
    display: block;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    line-height: 10em;
    color: var(--color__text--light);
    text-align: center;
  }
}

.media--full {
  width: 100%;

  @include breakpoint('large+') {
    padding-left: $navWidth;
  }
}

.media--bordered .media__img,
.media--bordered .post__video {
  position: relative;

  &::after {
    content:  '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    background: transparent;
    border: 1px solid var(--color__border);
    pointer-events: none;
    border-radius: inherit;
  }
}

.media__img {
  @include lazyloaded-picture;
  position: relative;
  height: 0;
  background-color: var(--color__border);
  overflow: hidden;
  border-radius: $border-radius;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }
}

/* SVGvariant */
.media__svg {
  background-color: var(--color__border);
  overflow: hidden;

  .media__outer {
    $marginSVG: (150/920) * 100%;
    $widthSVG: 100% - $marginSVG - $marginSVG;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: $widthSVG;
    min-height: 20vw;
    margin: #{$marginSVG};

    @include breakpoint('large+') {
      min-height: 320px;
    }
  }

  img {
    max-width: 100%;
  }
}

@include lazyload-animation(media__img);

$margin-top__caption:(
  'xsmall' : 30,
  'small+' : 30
);

.media__caption {
  @include f-caption;
  @include font-smoothing(on);
  display: block;
  margin-top: var(--spacing-03);
  color: var(--color__text--light);
}

/* Zoom */
.media[data-behavior="zoom"] {
  z-index: 1;
}

.media[data-behavior="zoom"] img {
  cursor: zoom-in;
}

.media[data-behavior="zoom"].media--zooming {
  z-index: $z-index-zoom;
}

.media[data-behavior="zoom"].media--zoomed {
  img {
    visibility: hidden;
  }

  .media__caption {
    visibility: hidden;
  }
}

.media[data-behavior="zoom"]:before {
  content: '';
  position: fixed;
  top: -100vh;
  left: -100vw;
  width: 100%;
  height: 100%;
  padding: 100vh 100vw;
  background: rgba(255,255,255,0.97);
  z-index: $z-index-zoom;
  opacity: 0;
  visibility: hidden;
  transform: translateZ(0);
  pointer-events: none;
  transition: opacity .3s ease, visibility 0s .3s;
}

.media.media--medium {
  max-width: 680px;
}

.media[data-behavior="zoom"].media--willzoom:before {
  display: block;
  pointer-events: auto;
}

.media[data-behavior="zoom"].media--zooming:before {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s ease;
}

.media[data-behavior="zoom"]::after {
  content:'';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  font-size: 5px;
  margin-top: -15px;
  margin-left: -15px;
  text-indent: -9999em;
  opacity: 0;
  border-top: 2px solid rgba(100, 100, 100, .33);
  border-right: 2px solid rgba(100, 100, 100, .33);
  border-bottom: 2px solid rgba(100, 100, 100, .33);
  border-left: 2px solid rgba(100, 100, 100, .8);
  border-radius: 50%;
  overflow: hidden;
  transform: translateZ(0);
  animation: rotatingSpinner 1.1s infinite linear;
  transition: opacity .1s;
}

.media[data-behavior="zoom"].media--loading::after {
  opacity: 1;
}

.media[data-behavior="zoom"].media--loading {
  img {
    opacity: 0.5;
  }
}

.media__clone {
  position:absolute;
  top: 80px;
  bottom: 80px;
  left: 80px;
  background-position:center center;
  background-size:cover;
  visibility: hidden;
  will-change: transform;
  cursor: zoom-out;
  z-index: $z-index-zoom + 1;
  transition: transform .3s;
}

@keyframes rotatingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.media__title {
  @include f-body-01-em;
  margin-top: var(--spacing-05);
}

.media__desc {
  @include f-body-01;
  margin-top: var(--spacing-01);
}

.media + .media__desc {
  margin-top: var(--spacing-04);
}
