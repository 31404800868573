@mixin lazyload-animation($element) {
    .#{$element} img {
        opacity: 1;
        transition: opacity .25s;
    }

    .#{$element} img[data-src]:not([src^="data:image"]) {
        opacity: 0;
    }

    .#{$element} img[data-srcset]:not([src^="data:image"]) {
        opacity: 0;
    }
}

@mixin lazyloaded-picture() {
    background-size: cover;
    background-position:center;

    &.js--lazyloaded {
        background-color:transparent !important;
        background-image:none !important;
    }
}

// @mixin float-clear
@mixin float-clear() {
  &::after {
    content: '.';
    display: block;
    clear: both;
    height: 0;
    line-height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

// @mixin background-fill
@mixin background-fill {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: -(map-get($inner-gutters, xsmall));
    right: -(map-get($inner-gutters, xsmall));
    top: 0;
    bottom: 0;
    background-color: inherit;
    pointer-events: none;

    @include breakpoint(small) {
      left: -(map-get($inner-gutters, small));
      right: -(map-get($inner-gutters, small));
    }

    @include breakpoint(medium) {
      left: -(map-get($inner-gutters, medium));
      right: -(map-get($inner-gutters, medium));
    }

    @include breakpoint(large) {
      left: -(map-get($inner-gutters, large));
      right: -(map-get($inner-gutters, large));
    }

    @include breakpoint(xlarge) {
      left: -99em;
      right: -99em;
    }
  }
}


// @mixin keyline-fill
@mixin keyline-full($position:top, $color: var(--color__background--dark)) {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    left: -(map-get($inner-gutters, xsmall));
    right: -(map-get($inner-gutters, xsmall));
    @if $position == top {
      bottom: 100%;
    } @else {
      top: 100%;
    }
    border-top: 1px solid $color;
    pointer-events: none;

    @include breakpoint(small) {
      left: -(map-get($inner-gutters, small));
      right: -(map-get($inner-gutters, small));
    }

    @include breakpoint(medium) {
      left: -(map-get($inner-gutters, medium));
      right: -(map-get($inner-gutters, medium));
    }

    @include breakpoint(large) {
      left: -(map-get($inner-gutters, large));
      right: -(map-get($inner-gutters, large));
    }

    @include breakpoint(xlarge) {
      left: -99em;
      right: -99em;
    }
  }
}

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@mixin visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

