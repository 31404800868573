/***
  Set to 100% to use the browsers font size as a base
***/

$global-font-size: 100% !default;


/***
  Font styles to use inside typo styles.
***/

$style-f-heading-01: (font-size: 80px, line-height: 88px, letter-spacing: 0, font-weight: 700);
$style-f-heading-02: (font-size: 60px, line-height: 68px, letter-spacing: 0, font-weight: 700);
$style-f-heading-03: (font-size: 32px, line-height: 36px, letter-spacing: 0.01em, font-weight: 700);
$style-f-heading-04: (font-size: 26px, line-height: 30px, letter-spacing: 0.01em, font-weight: 700);

$style-f-intro: (font-size: 24px, line-height: 36px, font-weight: 400);

$style-f-body-01: (font-size: 18px, line-height: 28px, font-weight: 400);
$style-f-body-01-em: (font-size: 18px, line-height: 28px, font-weight: 700);
$style-f-body-02: (font-size: 16px, line-height: 24px, font-weight: 400);
$style-f-body-02-em: (font-size: 16px, line-height: 24px, font-weight: 700);

$style-f-caption: (font-size: 12px, line-height: 18px, font-weight: 400);
$style-f-color: (font-size: 14px, line-height: 14px, letter-spacing: 0.05em, font-weight: 700);


/* Heading 01 */

$f-heading-01: generate-font-obj(
  (
    font-family: $waldenburg-bold-semi-condensed-font,
    settings: (
      'xsmall': $style-f-heading-02,
      'medium+': $style-f-heading-01
    )
  )
);

@mixin f-heading-01() {
  @include font-styles($f-heading-01);
}

%f-heading-01,
.f-heading-01 {
  @include f-heading-01;
}

/* Heading 02 */

$f-heading-02: generate-font-obj(
  (
    font-family: $waldenburg-bold-semi-condensed-font,
    settings: (
      'xsmall': $style-f-heading-03,
      'medium+': $style-f-heading-02
    )
  )
);

@mixin f-heading-02() {
  @include font-styles($f-heading-02);
}

%f-heading-02,
.f-heading-02 {
  @include f-heading-02;
}

/* Heading 03 */

$f-heading-03: generate-font-obj(
  (
    font-family: $waldenburg-bold-semi-condensed-font,
    settings: (
      'xsmall': $style-f-heading-04,
      'medium+': $style-f-heading-03
    )
  )
);

@mixin f-heading-03() {
  @include font-styles($f-heading-03);
}

%f-heading-03,
.f-heading-03 {
  @include f-heading-03;
}

/* Heading 03 alt */

$f-heading-03-alt: generate-font-obj(
  (
    font-family: $waldenburg-bold-semi-condensed-font,
    settings: (
      'xsmall': $style-f-heading-03
    )
  )
);

@mixin f-heading-03-alt() {
  @include font-styles($f-heading-03-alt);
}

%f-heading-03-alt,
.f-heading-03-alt {
  @include f-heading-03-alt;
}

/* Heading 04 */

$f-heading-04: generate-font-obj(
  (
    font-family: $waldenburg-bold-semi-condensed-font,
    settings: (
      'xsmall': $style-f-body-01-em,
      'medium+': $style-f-heading-04
    )
  )
);

@mixin f-heading-04() {
  @include font-styles($f-heading-04);
}

%f-heading-04,
.f-heading-04 {
  @include f-heading-04;
}

/* Intro */

$f-intro: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'xsmall': $style-f-body-01,
      'large+': $style-f-intro
    )
  )
);

@mixin f-intro() {
  @include font-styles($f-intro);
}

%f-intro,
.f-intro {
  @include f-intro;
}

/* Body 01 em */

$f-body-01-em: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'xsmall': $style-f-body-02-em,
      'small+': $style-f-body-01-em
    )
  )
);

@mixin f-body-01-em() {
  @include font-styles($f-body-01-em);
}

%f-body-01-em,
.f-body-01-em {
  @include f-body-01-em;
}

/* Body 01 */

$f-body-01: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'xsmall': $style-f-body-02,
      'small+': $style-f-body-01
    )
  )
);

@mixin f-body-01() {
  @include font-styles($f-body-01);
}

%f-body-01,
.f-body-01 {
  @include f-body-01;
}

/* Body 02 em */

$f-body-02-em: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'xsmall': $style-f-body-02-em
    )
  )
);

@mixin f-body-02-em() {
  @include font-styles($f-body-02-em, 700);
}

%f-body-02-em,
.f-body-02-em {
  @include f-body-02-em;
}

/* Body 02 */

$f-body-02: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'xsmall': $style-f-body-02
    )
  )
);

@mixin f-body-02() {
  @include font-styles($f-body-02);
}

%f-body-02,
.f-body-02 {
  @include f-body-02;
}

/* Caption */

$f-caption: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'xsmall': $style-f-caption
    )
  )
);

@mixin f-caption() {
  @include font-styles($f-caption);
}

%f-caption,
.f-caption {
  @include f-caption;
}


/* Color */

$f-color: generate-font-obj(
    (
      font-family: $waldenburg-bold-semi-condensed-font,
      settings: (
        'xsmall': $style-f-color
      )
    )
);

@mixin f-color() {
  @include font-styles($f-color);
}

%f-color,
.f-color {
  @include f-color;
}

/* Old styles */

// @mixin f-h1() {
// }

// @mixin f-h2() {
// }

// @mixin f-h3() {
// }

// @mixin f-h4() {
// }

// @mixin f-h5() {
// }

// @mixin f-intro() {
// }

// @mixin f-intro-simple() {
// }

// @mixin f-intro-size() {
// }

// @mixin f-body() {
// }

// @mixin f-body-simple() {
// }

// @mixin f-body-home() {
// }

// @mixin f-body-pad() {
// }

// @mixin f-body-size() {
// }

@mixin f-nav() {
}

// @mixin f-nav-simple() {
// }

// @mixin f-nav-pad() {
// }

// @mixin f-nav-size() {
// }

// @mixin f-largenav() {
// }

// @mixin f-largenav-simple() {
// }

// @mixin f-largenav-pad() {
// }

// @mixin f-largenav-size() {
// }

// @mixin f-small() {
// }

// @mixin f-caption() {
// }

// @mixin f-caption-size() {
// }

// @mixin f-note() {
// }
