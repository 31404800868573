@include breakpoint('medium-') {
  .searchResults {
    .main {
      padding-top: $header-h-small - 1px + $header-h-small;
    }

    .post__anchor {
      top: -#{$header-h-small / 2 + $header-h-small - 1px};
    }

    .cover ~ .post__anchor {
      top: -#{$header-h-small + $header-h-small - 1px};
    }
  }
}
