/* Font face */

@font-face {
  font-family: "Waldenburg";
  src: url("/dist/fonts/waldenburg/Waldenburg-Regular.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Waldenburg";
  src: url("/dist/fonts/waldenburg/Waldenburg-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "WaldenburgBoldSemiCondensed";
  src: url("/dist/fonts/waldenburg-semi-condensed/Waldenburg-Bold-SemiCondensed.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/***
  Variables for font families.
***/

$sans-serif-font: "Waldenburg", 'Arial', sans-serif;
$waldenburg-bold-semi-condensed-font: "WaldenburgBoldSemiCondensed", 'Arial', sans-serif;
