h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
dl {
  @include f-body-01;
  margin-top: 0;
}

b, strong {
  @include f-body-01-em;
}

h1,
h2,
h3 {
  @include f-body-01-em;
}

ul
li {
  list-style-type: none;
}

img {
  border: 0 none;
}

a {
  color: inherit;
  text-decoration: none;
}

.js-hide {
  display: none;
}

.js-show {
  display: block;
}
