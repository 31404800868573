:root {
  --spacing-01: 4px;
  --spacing-02: 8px;
  --spacing-03: 12px;
  --spacing-04: 16px;
  --spacing-05: 20px;
  --spacing-06: 24px;
  --spacing-07: 28px;
  --spacing-08: 32px;
  --spacing-09: 36px;
  --spacing-10: 40px;
  --spacing-11: 48px;
  --spacing-12: 60px;
  --spacing-13: 80px;
  --spacing-14: 100px;
  --spacing-15: 120px;
  --spacing-16: 140px;
  --spacing-17: 160px;
  --spacing-18: 180px;
  --spacing-19: 200px;
}
