.main {
  opacity:1;
  transition: opacity 0.3s ease;
}

.theme-header,
.header__logo,
.dropdown > a,
.search__toggle,
.header__toggle {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbarHeader,
.header__inner,
.navbarMenu,
.header__btn {
  opacity:1;
  transition: opacity 0.3s ease;
}

.header__loader {
  width:0;
  opacity:0;
  transition: opacity 0.3s ease, width 3s ease;
}

// .theme-navbar {
//   transition: background-color 0.3s ease, border-color 0.3s ease;
// }

.navbarLogo {
  transition: border-color 0.3s ease;
}

.pjax--loading {
  .main {
    opacity: 0;
  }

  .theme-header {
    color: var(--color__text)!important;
    background-color: var(--color__background);
    border-bottom: 1px solid var(--color__border);
  }

  .header__logo,
  .dropdown > a,
  .search__toggle,
  .header__toggle {
    color: var(--color__text)!important;
  }

  .header__loader {
    opacity:0.5;
    width:100%;
  }

  .header__btn {
    opacity: 0;
  }

  .navbar, .navbar.navbar--active {
    left: - #{$navWidth};

    @include breakpoint('large+') {
      left: 0;
      opacity:0;
    }
  }

  .navbar__overlay,
  .navbarHeader,
  .header__inner,
  .navbarMenu {
    opacity:0;
  }

  .headerPost__nav,
  .headerPost__prevNext {
    opacity:0;
  }

  // .theme-navbar {
  //   background-color:rgba(255,255,255,0);
  //   border-color:rgba(0,0,0,0);
  // }

  .navbarLogo {
    border-color:rgba(0,0,0,0);
  }
}
