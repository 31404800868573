$column-spans__even: (
    xsmall: 6,
    small: 6,
    medium: 6,
    large: 6,
    xlarge: 6
);

$column-spans__even-media: (
  xsmall: 6,
  small: 3,
  medium: 6,
  large: 6,
  xlarge: 6
);

.post-grid {
  @include columns-container-post-flex;
}

.post-grid__item {
  @include column-flex-post($column-spans__even);
  position: relative;
}

.post-grid__item-media {
  @include column-flex-post($column-spans__even-media);
}


.post-grid {
  .media {
    margin-top: var(--spacing-10);
  }

  .post-grid__item {
    &:nth-child(n+3) {
      .media {
        margin-top: var(--spacing-13);
      }
    }
  }
}
