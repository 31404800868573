/*
  A17 Guide

  This product is particular because we have 2 grids in one same site :
  1. one grid for the landing/listing pages and the homepage
  2. one grid for the posts/articles/guides with a fixed navigation showing on large, xlarge breakpoints
*/

/* header height */
$header-h-xsmall: 52px;
$header-h-small: 64px;
$header-h: 72px;

/* header vertical padding */
$header-xsmall-v-pad: 6px;
$header-small-v-pad: 12px;
$header-v-pad: 16px;

/* header horizontal padding */
$header-small-h-pad: 20px;
$header-h-pad: 24px;

/* Baseline grid height */
$base-height: 5;

/*
 Max widths the main column can run to over the breakpoints
 values can either be 'fluid' or a pixel value
 recommended xlarge is a px value and xsmall is fluid
*/

/* Post max-width */
$maxWidth:1280px - 55px - 55px;
$postMaxWidth:920px;

/* ------ Post grid - connected to grid-post mixins ------ */

/* navbar */
$navWidth: 250px;

$main-col-post-widths: (
  xlarge: $postMaxWidth + $navWidth,
  large: 'fluid',
  medium: 'fluid',
  small: 'fluid',
  xsmall: 'fluid'
);

/* Inner gutters, in px or vw, of each breakpoint */
$inner-post-gutters: (
  xlarge: 40px,
  large: 40px,
  medium: 40px,
  small: 20px,
  xsmall: 20px
);

/* Outer gutters, in px or vw, of each breakpoint */
$outer-post-gutters: (
  xlarge: 55px,
  large: 55px,
  medium: 40px,
  small: 40px,
  xsmall: 20px
);

/* How many columns are in each breakpoint */
$column-post-count: (
  xlarge: 12,
  large: 12,
  medium: 12,
  small: 6,
  xsmall: 6
);

/* Texts in posts are not full width */
/*$column-spans__text: (
  xsmall: 6,
  small: 6,
  medium: 9,
  large: 9,
  xlarge: 9
);*/


/* ------ Listing grid - connected to grid mixins ------ */

$main-col-widths: (
  xlarge: $maxWidth - 10px,
  large: 'fluid',
  medium: 'fluid',
  small: 'fluid',
  xsmall: 'fluid'
);

/* Inner gutters, in px or vw, of each breakpoint */
$inner-gutters: (
  xlarge: 40px,
  large: 40px,
  medium: 30px,
  small: 20px,
  xsmall: 20px
);

/* Outer gutters, in px or vw, of each breakpoint */
$outer-gutters: (
  xlarge: 60px,
  large: 60px,
  medium: 40px,
  small: 40px,
  xsmall: 20px
);

/* How many columns are in each breakpoint */
$column-count: (
  xlarge: 12,
  large: 12,
  medium: 12,
  small: 6,
  xsmall: 6
);


/*
  Breakpoint information, where each starts and stops
  if a breakpoint is not fluid, then the start value is equal to the main col value plus 2x the gutter at this breakpoint
*/
$breakpoints: (
  xsmall: (start: null, end: 599),
  small: (start: 600, end: 769),
  medium: (start: 770, end: 1023),
  large: (start: 1024, end: 1279),
  xlarge: (start: 1280, end: null)
);

/* Uniform border radius */
$border-radius: 20px;

/* Some easing functions */
$bezier--bounce: cubic-bezier(.5, -.6, .5, 1.6);
$bezier--ease-in-out: cubic-bezier(.5, 0, .5, 0);

/* z-index */

$z-index-panel:40;

$z-index-search:30;

$z-index-zoom:20;

$z-index-headerPost:13;

$z-index-header:13;

$z-index-headerOverlay:10;

$z-index-navbar:8;

$z-index-logo:5;

$z-index-alert: 99;

