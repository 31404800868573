@include breakpoint(xsmall) {
  .hide--xsmall {
    display: none !important;
  }
}

@include breakpoint(small) {
  .hide--small {
    display: none !important;
  }
}

@include breakpoint(medium) {
  .hide--medium {
    display: none !important;
  }
}

@include breakpoint(large) {
  .hide--large {
    display: none !important;
  }
}

@include breakpoint(xlarge) {
  .hide--xlarge {
    display: none !important;
  }
}

.visually-hidden {
  @include visually-hidden;
}
