/* spacing vertical & horizontal */
$headerPost-small-h: $header-h-small - $header-small-v-pad - $header-small-v-pad;
$headerPost-h: $header-h - $header-v-pad - $header-v-pad;

.search {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color__background--ultra-light);
  background-clip: padding-box;
  z-index: 1;
}

.search__form {
  box-sizing: border-box;
  display: flex;
  padding: $header-xsmall-v-pad $header-small-h-pad;
  height: $header-h-xsmall;
  overflow: hidden;
  box-shadow: inset 0px -1px 0px 0px transparent;

  @include breakpoint('small+') {
    padding: $header-small-v-pad $header-small-h-pad;
    height: $header-h-small;
  }

  @include breakpoint('large+') {
    height: $header-h;
    padding: $header-v-pad $header-h-pad;
  }
}

.search__toggle {
  -webkit-appearance: none;
  display: block;
  margin: 0 0 0 var(--spacing-04);
  padding: 0;
  border: 0 none;
  font-family: inherit;
  font-size: inherit;
  color: var(--color__text);
  background-color: transparent;
  cursor: pointer;
  z-index: 2;
  transition: color 0.3s ease;

  &:hover {
    color: var(--color__text--light);
  }

  &:active {
    outline: 0;
  }

  .icon--search {
    display: block;
  }

  .icon--close {
    display: none;
    transition: color 0.3s ease;
  }

  @include breakpoint('large+') {
    margin: 0 0 0 var(--spacing-05);
  }
}

.search__toggle ~ ul {
  margin-right: 20px + $header-small-h-pad;

  @include breakpoint('small+') {
    margin-right: 20px + $header-h-pad;
  }
}

.search__submit {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  cursor: pointer;

  &:active {
    outline: 0;
    box-shadow: none;
  }
}

.search__submit,
.search__input {
  height: 100%;
}

.search__submit .icon {
  display: block;
}

.search__input {
  @include f-intro;
  -webkit-appearance: none;
  display: block;
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: var(--spacing-03);
  font-family: inherit;
  color: var(--color__text);
  background-color: transparent;
  border: 0 none;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
      display: none;
  }

  &::-ms-clear {
      display: none;
  }

  &::-ms-reveal {
      display: none;
  }

  &:focus {
      outline: 0;
      box-shadow: none;
  }

  @include placeholder {
    color: rgba(0,0,0,0.2);
  }

  &:focus,
  &:hover {
    color: var(--color__text);
  }
}

.search__autocomplete {
  background-clip: padding-box;

  ul {
    padding: var(--spacing-05) $header-small-h-pad var(--spacing-05) $header-small-h-pad;

    li {
      @include f-intro;
    }

    @include breakpoint('small+') {
      padding: var(--spacing-05) $header-h-pad;
    }
  }
}
