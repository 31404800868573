.headerPost {
  opacity:1;
  transition: opacity 0.3s linear;
}

.page-zoom {
  .headerPost {
    opacity:0;
  }
}
