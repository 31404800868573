.files {
  max-width: colspan-post(9, 'xlarge', 0px, $navWidth);
  margin-top: var(--spacing-08);
  border: 1px solid var(--color__border);
  border-radius: $border-radius;
  overflow: hidden;

  @include breakpoint('small+') {
    margin-top: var(--spacing-11);
  }
}

.files__item + .files__item {
  border-top: 1px solid var(--color__border);
}

.files__link {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--spacing-04) var(--spacing-08) var(--spacing-04) 56px;

  .icon--download {
    position: absolute;
    display: block;
    top: var(--spacing-04);
    left: var(--spacing-05);
  }

  &:hover {
    background: var(--color__background--ultra-light);
  }
}

.files__name {
  @include f-body-02;
  flex: 1;
  padding-right: var(--spacing-06);
}

.files__size {
  @include f-body-02;
  color: var(--color__text--light);
  text-transform: uppercase;

  @include breakpoint('xsmall') {
    display: block;
    width: 100%;
  }
}

