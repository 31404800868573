/* spacing vertical & horizontal */
$headerPost-small-h: $header-h-small - $header-small-v-pad - $header-small-v-pad;
$headerPost-h: $header-h - $header-v-pad - $header-v-pad;

.userInfo {
  box-sizing: border-box;
  padding: $header-small-v-pad 0 $header-small-v-pad $header-small-h-pad;
  text-align: left;
  overflow: visible;

  .userInfo__dropdown > a {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .icon--dropdown-small {
    margin-right: calc(var(--spacing-01) * -1);
  }
}

.userInfo__name {
  @include breakpoint('xsmall') {
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
