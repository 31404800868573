$column-spans__featured: (
    xsmall: 6,
    small: 3,
    medium: 6,
    large: 6,
    xlarge: 6
);

$column-spans__featuredPrimary: (
    xsmall: 6,
    small: 6,
    medium: 12,
    large: 12,
    xlarge: 12
);

.featured {
  @include columns-container-flex;
  margin-top: var(--spacing-10);

  @include breakpoint('small+') {
    margin-top: var(--spacing-13);
  }
}

.featured__item {
  @include column-flex($column-spans__featured);
  position: relative;
  margin-bottom: var(--spacing-10);

  a {
    display: block;
    height: 100%;
    background: var(--color__background);
    border-radius: $border-radius;
  }
}

.featured__media {
  img {
    display: block;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    line-height: 10em;
    color: var(--color__text--light);
    text-align: center;
  }
}

.featured__mediaWrapper {
  height: 0;
  padding-bottom: (380 / 560) * 100%;
  background-color: var(--color__border);
}

.featured__item--primary {
  @include column-flex($column-spans__featuredPrimary);

  a {
    display: block;

    @include breakpoint('small+') {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;

      > * {
        width: 50%;
      }
    }
  }

  .featured__mediaWrapper {
    padding-bottom: 100%;
  }
}
