$colorPadding:30px;
$colorPadding-small:20px;

.color {
  @include f-caption;
  //height:0;
  //padding-bottom:100%;
  margin-top:50px;
  //overflow:hidden;
  position: relative;
  min-height: 200px;

  //&::after {
  //  content:'';
  //  display:block;
  //  border:1px solid var(--color__border);
  //  position:absolute;
  //  top:0;
  //  left:0;
  //  right:0;
  //  bottom:0;
  //  pointer-events:none;
  //  height:100%;
  //  width:100%;
  //  box-sizing:border-box;
  //}

  @include breakpoint('small+') {
    margin-top:60px;
    padding-bottom:0;
  }
}

.color__info {
  padding:$colorPadding-small;
  position:absolute;
  top:0;

  @include breakpoint('small+') {
    padding:$colorPadding;
  }
}

.color + .color {
  margin-top:35px;
  @include breakpoint('small+') {
    margin-top:45px;
  }
}

.color--dark {
  color: var(--color__background);
}

.color dl {
  @include f-body-02;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
}

.color dt {
  margin-right: var(--spacing-03);
}

.color dd {
  flex-grow: 1;
}

.color {
  h1, h2, h3, h4, h5 {
    margin: 0;
  }
}
