.tag {
  @include f-body-02;
  box-sizing: border-box;
  display: inline-flex;
  padding: var(--spacing-01) var(--spacing-03);
  color: var(--color__text);
  white-space: nowrap;
  overflow: visible;
  background-color: var(--color__background--light);
  //border: 1px solid var(--color__text--light);
  border-radius: 999px;
}

.tag--greyed {
  padding: 0;
  background-color: transparent;
}
