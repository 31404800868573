/*
  @mixin placeholder

  Style form placeholder text
*/

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}


/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
@mixin styled-select(){
  // Container used for styling the custom select, the buttom class below adds the bg gradient, corners, etc.
  position: relative;
  display: block;

  // This is the native select, we're making everything but the text invisible so we can see the button styles in the wrapper
  select {
    width: 100%;
    margin: 0;
    outline: none;
    padding: 0 20px 0 0;

    // Prefixed box-sizing rules necessary for older browsers
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    // Font size must be 16px to prevent iOS page zoom on focus
    font-size: 18px;
    option {
      color: var(--color__text--on-dark);
    }
  }



  // Custom arrow sits on top of the select - could be an image, SVG, icon font, etc. or the arrow could just baked into the bg image on the select.
  &::after {
    @include icon();
    content: '';
    position: absolute;
    z-index: 2;
    right: 0;
    top: 10px;
    opacity: .2;
    // These hacks make the select behind the arrow clickable in some browsers
    pointer-events: none;
    display: none;
  }

  @supports ( -webkit-appearance: none ) or ( appearance: none ) or ( ( -moz-appearance: none ) and ( mask-type: alpha ) ) {
    // Firefox <= 34 has a false positive on @supports( -moz-appearance: none ) @supports ( mask-type: alpha ) is Firefox 35+

    // Show custom arrow
    &::after {
      display: block;
    }

    // Remove select styling
    select {
      padding-right: 20px; // Match-01
      // inside @supports so that iOS <= 8 display the native arrow
      background: none; // Match-04
      // inside @supports so that Android <= 4.3 display the native arrow
      border: 1px solid transparent; // Match-05

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    select:focus {
      color: var(--color__text--on-dark); // Match-03
    }
  }

  // IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance. Targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting
  // The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    select::-ms-expand {
      display: none;
    }

    select:focus {
      color: var(--color__text--on-dark); // Match-03
    }

    select:focus::-ms-value {
      background: transparent;
      color: var(--color__text--on-dark); // Match-02
    }

    select {
      padding-right: 2em; // Match-01
      background: none; // Match-04
      border: 1px solid transparent; // Match-05
    }

    &::after {
      display: block;
    }
  }
}
