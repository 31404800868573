.icon--ae, .icon--ae svg { width: 20px; height: 26px }
.icon--ai, .icon--ai svg { width: 20px; height: 26px }
.icon--arrow-down, .icon--arrow-down svg { width: 15px; height: 15px }
.icon--arrow-up, .icon--arrow-up svg { width: 15px; height: 15px }
.icon--ase, .icon--ase svg { width: 20px; height: 26px }
.icon--burger, .icon--burger svg { width: 24px; height: 24px }
.icon--close, .icon--close svg { width: 24px; height: 24px }
.icon--copy, .icon--copy svg { width: 24px; height: 24px }
.icon--dir, .icon--dir svg { width: 26px; height: 21px }
.icon--dmg, .icon--dmg svg { width: 20px; height: 26px }
.icon--doc, .icon--doc svg { width: 20px; height: 26px }
.icon--download, .icon--download svg { width: 24px; height: 24px }
.icon--dropdown-small, .icon--dropdown-small svg { width: 24px; height: 24px }
.icon--dropdown, .icon--dropdown svg { width: 12px; height: 8px }
.icon--eps, .icon--eps svg { width: 20px; height: 26px }
.icon--fla, .icon--fla svg { width: 20px; height: 26px }
.icon--fnt, .icon--fnt svg { width: 20px; height: 26px }
.icon--gen, .icon--gen svg { width: 20px; height: 26px }
.icon--grid, .icon--grid svg { width: 24px; height: 19px }
.icon--html, .icon--html svg { width: 20px; height: 26px }
.icon--img, .icon--img svg { width: 20px; height: 26px }
.icon--indd, .icon--indd svg { width: 20px; height: 26px }
.icon--key, .icon--key svg { width: 20px; height: 26px }
.icon--merlin, .icon--merlin svg { width: 20px; height: 26px }
.icon--net, .icon--net svg { width: 26px; height: 21px }
.icon--numbers, .icon--numbers svg { width: 20px; height: 26px }
.icon--pages, .icon--pages svg { width: 20px; height: 26px }
.icon--pdf, .icon--pdf svg { width: 20px; height: 26px }
.icon--ppt, .icon--ppt svg { width: 20px; height: 26px }
.icon--psd, .icon--psd svg { width: 20px; height: 26px }
.icon--search, .icon--search svg { width: 24px; height: 24px }
.icon--snd, .icon--snd svg { width: 20px; height: 26px }
.icon--sql, .icon--sql svg { width: 20px; height: 26px }
.icon--swf, .icon--swf svg { width: 20px; height: 26px }
.icon--toc, .icon--toc svg { width: 24px; height: 24px }
.icon--txt, .icon--txt svg { width: 20px; height: 26px }
.icon--vid, .icon--vid svg { width: 20px; height: 26px }
.icon--xls, .icon--xls svg { width: 20px; height: 26px }
.icon--zip, .icon--zip svg { width: 20px; height: 26px }
