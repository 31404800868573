.navbar {
  position: fixed;
  top: 0;
  bottom: 0;
  display: block;
  width: 100%;
  right: -100%;
  background: var(--color__background);
  background-clip: padding-box;
  //border-right: 1px solid var(--color__border);
  visibility: hidden;
  z-index: $z-index-header + 1;
  transform: translateZ(0);
  transform-style: preserve-3d;
  transition: opacity 0.35s ease, right 0.35s ease, visibility 0s 0.35s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);

  &.navbar--active {
    right: 0;
    visibility: visible;
    transition: opacity 0.35s ease, right 0.35s;
  }

  @include breakpoint('small+') {
    width: 376px;
    right: -376px;
  }

  @include breakpoint('large+') {
    left: 0;
    right: auto;
    width: #{((250 / 1280) * 100%)};
    min-width: $navWidth;
    max-width: 400px;
    visibility: visible;
    z-index: $z-index-navbar;
    transition: opacity 0.35s ease, left 0.35s;
  }
}

.navbar__overlay {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background: var(--color__overlay);
  opacity: 0;
  visibility: hidden;
  z-index: $z-index-header;
  cursor: pointer;
  transition: opacity 0.35s ease, visibility 0s 0.35s, height 0s 0.35s;

  @include breakpoint('large+') {
    display: none;
  }
}

.navbar--active + .navbar__overlay {
  height: 100%;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.35s;
}

.navbar__wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;

  // @include breakpoint('large+') {
  //   top: $header-h;
  // }
}

.navbar__wrapper > div {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
  padding-top: 0;
  padding-bottom: var(--spacing-05);
  background-color: inherit;
}

.navbar__copy {
  @include f-caption;
  position: absolute;
  bottom: 0;
  padding: 0 var(--spacing-06) var(--spacing-06) var(--spacing-06);
  color: var(--color__text--light);
}


.navbar--home,
.navbar--search {
  @include breakpoint('large+') {
    left: - #{$navWidth};
    visibility: hidden;

    &.navbar--active {
      left: - #{$navWidth};
      visibility: hidden;
    }

    &.navbar--active + .navbar__overlay {
      opacity: 0;
      visibility: hidden;
    }
  }
}

/* Search results specific UI */
.navbar--searchResults {
  .navbarMenu__toggleIcon {
    display: none;
  }

  @include breakpoint('medium-') {
    position: fixed;
    top: $header-h-small - 1px;
    left: 0;
    right: 0;
    bottom: auto;
    width: 100%;
    max-height: calc(100% - #{$header-h-small});
    background-color: transparent !important;
    border-right: 0 none;
    visibility: visible;
    z-index: $z-index-header - 1;

    .navbar__wrapper {
      position: static;
      overflow: hidden;

      > div {
        padding: 0;
      }
    }

    .navbarMenu__guides {
      margin: 0;
      padding: 0;
    }

    .navbarMenu__toggle {
      padding-left: $header-h-pad;
      //padding-right: #{$header-h-pad + 28px};

      .navbarMenu__toggleIcon {
        top: var(--spacing-05);
      }
    }

    .navbarMenu__toggleIcon {
      display: block;
      right: var(--spacing-07);
    }

    .navbarMenu__pagesContainer {
      height: calc(100vh - #{$header-h} - 60px);
      max-height: 0;
      overflow-x: hidden;
      overflow-y: auto;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: opacity 0.35s ease, visibility 0s 0.35s, max-height 0s 0.35s;
    }

    &.navbar--expanded .navbarMenu__pagesContainer {
      max-height: calc(100vh - #{$header-h} - 60px);
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transition: opacity 0.35s;
    }
  }

  @include breakpoint('xsmall') {
    top: $header-h-small - 1px;
    max-height: calc(100% - #{$header-h-small});

    .navbarMenu__toggle {
      padding-left: $header-small-h-pad;
      padding-right: #{$header-small-h-pad + 28px};
    }

    .navbarMenu__pagesContainer {
      height: calc(100vh - #{$header-h-small} - 60px);
      max-height: 0;
    }

    &.navbar--expanded .navbarMenu__pagesContainer {
      max-height: calc(100vh - #{$header-h-small} - 60px);
    }
  }

  @include breakpoint('large+') {
    top: $header-h - 1px;
    max-height: calc(100% - #{$header-h});
  }
}
