.dropdown {
  position: relative;

  > a {
    color: var(--color__text);
    transition: color 0.35s ease;
  }
}

.dropdown__list {
  position: absolute;
  top: calc(100% + var(--spacing-02));
  right: 0;
  display: none;
  min-width: 200px;
  max-width: calc(100vw - var(--spacing-03));
  padding: var(--spacing-03) 0;
  background: var(--color__background);
  box-shadow: 0 0 5px rgba(0,0,0,.3);

  a,
  .dropdown__item {
    @include f-body-02;
    display: block;
    // min-width: 100px;
    height: var(--spacing-10);
    padding: 0 var(--spacing-08) 0 var(--spacing-05);
    line-height: var(--spacing-10);
    color: var(--color__text--light);
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.35s ease;

    &:hover {
      color: var(--color__text);
      background-color: var(--color__background--ultra-light);
    }
  }
}

.dropdown > a:hover,
.dropdown--active > a {
  color: var(--color__text--light);

  .icon {
    color: var(--color__text--light);
  }
}

.dropdown--active {
  .dropdown__list {
    display: block;
  }
}

.dropdown__sep {
  position: relative;
  margin-bottom: var(--spacing-05);
}

.dropdown__sep::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  display: block;
  height: 1px;
  width: 100%;
  background: var(--color__border);
}
