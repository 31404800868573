.icon {
  display: inline-block;
}

.icon svg {
  display: inherit;
  width: 100%;
  height: 100%;
  vertical-align: inherit;
}
