.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: var(--color__background--error);
  color: var(--color__text--on-dark);
}

.error__title {
  @include f-heading-01;
}

.error__desc {
  @include f-body-01;
  padding: 0 var(--spacing-05);
  margin-top: var(--spacing-05);
  text-align: center;

  @include breakpoint('medium+') {
    margin-top: var(--spacing-10);
  }
}
