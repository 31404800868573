.panel {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0s 0.3s;
}

.page-panel {
  overflow: hidden;

  body {
    overflow: hidden;
    height: 100%;
  }

  .panel {
    pointer-events: auto;
    visibility: visible;
    transform: translateY(0); // todo refine animation
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
}
