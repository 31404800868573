$column-spans__small: (
  xsmall: 6,
  small: 6,
  medium: 6,
  large: 4,
  xlarge: 4
);

.brand-colors-grid {
  @include columns-container-post-flex;
}

.brand-colors-grid .brand-colors {
  @include column-flex-post($column-spans__small);
}

.brand-colors {
  border-radius: $border-radius;
  overflow: hidden;
  margin-top: 50px;

  @include breakpoint('small+') {
    margin-top: 60px;
    padding-bottom:0;
  }
}

.brand-color {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.brand-color__info {
  flex-grow: 1;
  padding-left: var(--spacing-05);
  padding-right: var(--spacing-05);
  text-transform: uppercase;
}

.brand-color--dark {
  color: var(--color__background);
}

.brand-color dl {
  @include f-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brand-color dd {
  text-align: right;
}
