.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: $header-h-xsmall - 1px;

  @include breakpoint('small+') {
      padding-top: $header-h-small - 1px;
  }

  @include breakpoint('large+') {
    padding-top: $header-h - 1px;
  }

  > * {
    padding-top: 1px;
  }
}

.main--preview {
  margin-top: 0;
  padding-top: 0;

  @include breakpoint('small+') {
    margin-top: 0;
    padding-top: 0;
  }

  > * {
    margin-top: 0;
    padding-top: 0;
  }
}

.main--hub {
  background: var(--color__background);

  + .footer {
    background: var(--color__background);
  }
}
