/* Global */

.ds {
  .hub,
  .color,
  .files,
  .post__text {
    margin-top: 0;
  }
}

.ds__single {
  & + & {
    margin-top: var(--spacing-12);
    padding-top: var(--spacing-10);
    border-top: 1px solid var(--color__border);
  }
}

.ds__title {
  @include f-heading-03;
  margin-bottom: var(--spacing-06) !important;
}

.ds__pre {
  display: inline-flex;
  padding: 0 var(--spacing-03);
  font-size: 12px;
  font-family: 'SFMono-Regular', 'Consolas', 'Liberation Mono', 'Menlo', 'Courier', monospace;
  background-color: #ececec;
}

/* Section */

.ds__section-header {
  @include font-smoothing(on);
  padding: var(--spacing-14) 0;
  color: var(--color__text--on-dark);
  background-color: var(--color__background--dark);
}

.ds__section-content {
  padding: var(--spacing-11) 0;
}

.ds__section-title {
  @include f-heading-01;
}

.ds__section-intro {
  @include f-intro;
  margin-top: var(--spacing-04);
}

.ds__section-subtitle {
  @include f-heading-03;
  margin-bottom: var(--spacing-05)
}

/* Table */

.ds__table {
  table-layout: fixed;
  width: 100%;
  background-color: var(--color__background);

  tr {
    border-bottom: 1px solid #F2F2F2;
  }

  th,
  td {
    padding: var(--spacing-03) var(--spacing-05);
    vertical-align: top;
  }

  th {
    @include f-body-02-em;
    text-align: left;
  }

  td {
    @include f-body-02;
  }
}

/* Spacing */

.ds__spacing {
  display: block;
  background-color: #0088FF;

  &--01 {
    width: var(--spacing-01);
    height: var(--spacing-01);
  }

  &--02 {
    width: var(--spacing-02);
    height: var(--spacing-02);
  }

  &--03 {
    width: var(--spacing-03);
    height: var(--spacing-03);
  }

  &--04 {
    width: var(--spacing-04);
    height: var(--spacing-04);
  }

  &--05 {
    width: var(--spacing-05);
    height: var(--spacing-05);
  }

  &--06 {
    width: var(--spacing-06);
    height: var(--spacing-06);
  }

  &--07 {
    width: var(--spacing-07);
    height: var(--spacing-07);
  }

  &--08 {
    width: var(--spacing-08);
    height: var(--spacing-08);
  }

  &--09 {
    width: var(--spacing-09);
    height: var(--spacing-09);
  }

  &--10 {
    width: var(--spacing-10);
    height: var(--spacing-10);
  }

  &--11 {
    width: var(--spacing-11);
    height: var(--spacing-11);
  }

  &--12 {
    width: var(--spacing-12);
    height: var(--spacing-12);
  }

  &--13 {
    width: var(--spacing-13);
    height: var(--spacing-13);
  }

  &--14 {
    width: var(--spacing-14);
    height: var(--spacing-14);
  }

  &--15 {
    width: var(--spacing-15);
    height: var(--spacing-15);
  }

  &--16 {
    width: var(--spacing-16);
    height: var(--spacing-16);
  }

  &--17 {
    width: var(--spacing-17);
    height: var(--spacing-17);
  }

  &--18 {
    width: var(--spacing-18);
    height: var(--spacing-18);
  }

  &--19 {
    width: var(--spacing-19);
    height: var(--spacing-19);
  }
}

/* Colors */

.ds-colors__list {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: calc(var(--spacing-06) * -1);

  & + & {
    margin-top: var(--spacing-06);
  }
}

.ds-colors__item {
  @include f-caption;
  position: relative;
  width: 250px;
  height: 125px;
  margin-right: var(--spacing-06);
  margin-bottom: var(--spacing-06);
  padding: 34px 16px 0;
  border: 1px solid #e6e6e6;

  /* Shades */

  &--white {
    background-color: var(--color__white);
  }

  &--black {
    background-color: var(--color__black);

    .ds-colors__hexa {
      color: var(--color__text--on-dark);
    }
  }

  &--black-54 {
    background-color: var(--color__black--54);

    .ds-colors__hexa {
      color: var(--color__text--on-dark);
    }
  }

  &--black-20 {
    background-color: var(--color__black--20);
  }

  &--black-10 {
    background-color: var(--color__black--10);
  }

  &--black-05 {
    background-color: var(--color__black--05);
  }

  &--black-03 {
    background-color: var(--color__black--03);
  }

  &--black-a-75 {
    background-color: var(--color__black--a-75);

    .ds-colors__hexa {
      color: var(--color__text--on-dark);
    }
  }

  &--blue {
    background-color: var(--color__blue);

    .ds-colors__hexa {
      color: var(--color__text--on-dark);
    }
  }

  &--yellow {
    background-color: var(--color__yellow);
  }

  /* Usages */

  &--background {
    background-color: var(--color__background);
  }

  &--background-dark {
    background-color: var(--color__background--dark);

    .ds-colors__hexa {
      color: var(--color__text--on-dark);
    }
  }

  &--background-light {
    background-color: var(--color__background--light);
  }

  &--background-ultra-light {
    background-color: var(--color__background--ultra-light);
  }

  &--text {
    background-color: var(--color__text);

    .ds-colors__hexa {
      color: var(--color__text--on-dark);
    }
  }

  &--text-on-dark {
    background-color: var(--color__text--on-dark);
  }

  &--text-light {
    background-color: var(--color__text--light);

    .ds-colors__hexa {
      color: var(--color__text--on-dark);
    }
  }

  &--text-highlight {
    background-color: var(--color__text--highlight);
  }

  &--text-placeholder {
    background-color: var(--color__text--placeholder);
  }

  &--overlay {
    background-color: var(--color__overlay);

    .ds-colors__hexa {
      color: var(--color__text--on-dark);
    }
  }

  &--overlay-light {
    background-color: var(--color__overlay--light);
  }

  &--border {
    background-color: var(--color__border);
  }

  &--brand {
    background-color: var(--color__brand);

    .ds-colors__hexa {
      color: var(--color__text--on-dark);
    }
  }
}

.ds-colors__code {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  font-size: 12px;
  padding: var(--spacing-02) var(--spacing-04);
  background-color: var(--color__background);
  border-top: 1px solid #e6e6e6;
  z-index: 2;
}

/* Typo */

.ds-typo__item + .ds-typo__item {
  margin-top: var(--spacing-11);
}
