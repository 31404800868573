$column-spans__hubHeader: (
    xsmall: 6,
    small: 6,
    medium: 6,
    large: 6,
    xlarge: 6
);

.hubHeader {
  padding-bottom: var(--spacing-13);
}

.hubHeader--branded {
  position: relative;
  padding-bottom: var( --spacing-17);
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

.hubHeader--image {
  min-height: 400px;

  @include breakpoint('small') {
    min-height: 440px;
  }

  @include breakpoint('medium') {
    min-height: 600px;
  }

  @include breakpoint('large+') {
    min-height: 600px;
  }
}

.hubHeader__img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;

  img {
    height: 100%;
    display: block;
    margin: 0 auto;
    margin-left: 50vw;
    transform: translateX(-50%);
  }

  + .content {
    position: relative;
    z-index: 1;
  }
}

.objectFit .hubHeader__img img {
  width: 100%;
  height: 100%;
  margin: 0;
  object-position: 50% 50%;
  object-fit: cover;
  transform: none;
}

.hubHeader--light {
  color: var(--color__background);
  background: black;
}

.hubHeader__inner {
  @include columns-container-flex;

  > * {
    @include column-flex($column-spans__hubHeader);
  }

  .f-heading-01 {
    margin-top: var(--spacing-13);

    &::after {
      content: '';
      mix-blend-mode: difference;
    }
  }

  .f-intro {
    margin-top: var(--spacing-13);
  }
}
