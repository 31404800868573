// ####################################################
// Font setup mixins
//
// Use the serif/sans-serif mixins directly in the SCSS do any responsive overwrites - see setup/_typography.scss
// See
// * https://codepen.io/13twelve/pen/WYgrYE?editors=1000
// * https://code.area17.com/a17/fe-boilerplate/wikis/scss-setup-typography
// * https://code.area17.com/a17/fe-boilerplate/wikis/scss-setup-mixins-typography

@function get-primary-font-name($font) {
  // check for a font family rather than a single font name
  $font-family-comma-index: str-index("#{$font}", ',');
  @if $font-family-comma-index {
    // get the primary font from the font family
    $font: str-slice(#{$font}, 0, $font-family-comma-index - 1);
    $font: str-replace($font, '"', '');
  }
  @return $font;
}

@function generate-font-obj($obj) {
  $_obj: ();
  $_settings: ();

  @each $key, $value in $obj {
    @if ($key != 'settings') {
      $_obj: map-merge($_obj, ($key: $value));
    }
  }

  $font-family: map-get($obj, font-family);
  $settings: map-get($obj, settings);
  // work out the primary font name (used in the cap heights map)
  $font-name: get-primary-font-name($font-family);
  // ok if we have settings
  @if $settings {
    // set some defaults, we we can keep track of changes so tuck calc doesn't get null values
    $font-size: 0;
    $line-height: 0;
    @each $breakpoint, $font-info in $settings {
      // get font info for this breakpoint
      @if map-has-key($font-info, font-size) {
        // conditional here because this can inherit from earlier items in the map so that the tuck calculation can take place
        $font-size: map-get($font-info, font-size);
      }
      @if map-has-key($font-info, line-height) {
        $line-height: map-get($font-info, line-height);
      }
      $_settings: map-merge($_settings, (#{$breakpoint}: $font-info));
    }
  }
  //
  $_obj: map-merge($_obj, (settings: $_settings));
  @return $_obj;
}

@mixin font-style($font-size: false, $line-height: false, $font-weight: false, $font-style: false, $letter-spacing: false, $text-transform: false) {
  // set font settings
  @if $font-size {
    font-size: rem-calc($font-size);
  }
  @if $line-height {
    line-height: rem-calc($line-height);
  }
  @if $font-weight {
    font-weight: $font-weight;
  }
  @if $font-style {
    font-style: $font-style;
  }
  @if $letter-spacing {
    letter-spacing: $letter-spacing;
  }
  @if $text-transform {
    text-transform: $text-transform;
  }
}

@mixin font-styles($obj,$bolderWeight: false) {
  $font-family: map-get($obj, font-family);
  $settings: map-get($obj, settings);
  // set base font family
  @if $font-family {
    font-family: $font-family;
  } @else {
    @warn "No fall back font family specified.";
  }
  @if $bolderWeight {
    b,
    strong {
      font-weight: $bolderWeight;
    }
  } @else {
    b,
    strong {
      font-weight: 600;
    }
  }
  i,
  em {
    font-style: italic;
  }

  // set the various font info per breakpoint
  @if $settings and $font-family {
    $font-size: 0;
    $line-height: 0;
    // loop the breakpoints in the setttings
    @each $breakpoint, $font-info in $settings {
      // check this is a valid breakpoint
      @if map-has-key($breakpoints-with-directions, $breakpoint) {
        // get font info for this breakpoint
        @if map-has-key($font-info, font-size) {
          // conditional here because this can inherit from earlier items in the map so that the tuck calculation can take place
          $font-size: map-get($font-info, font-size);
        }
        @if map-has-key($font-info, line-height) {
          $line-height: map-get($font-info, line-height);
        }
        $font-weight: map-get($font-info, font-weight);
        $font-style: map-get($font-info, font-style);
        $letter-spacing: map-get($font-info, letter-spacing);
        $text-transform: map-get($font-info, text-transform);

        // check if this is the smallest breakpoint (the default font setting)
        @if (index(map-keys($settings), $breakpoint) == 1) {
          @include font-style($font-size, $line-height, $font-weight, $font-style, $letter-spacing, $text-transform);
        } @else {
          @include breakpoint(#{$breakpoint}) {
            @include font-style($font-size, $line-height, $font-weight, $font-style, $letter-spacing, $text-transform);
          }
        }
      } @else {
        @warn "Setting font info to unknown breakpoint: `#{$breakpoint}`";
      }
    }
  } @else {
    @warn "No font settings specified.";
  }
}

/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

/*
  @mixin hide_text

  Hides text in an element
*/

@mixin hide-text() {
  color: transparent;
  font: 0/0 a;
  text-indent: -100%;
  text-shadow: none;
  overflow: hidden;
}


/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}


/*
    @mixin bordered

    Parameters:
    $color - color
    $color--hover - color for hover
    $pos - vertical position (in % or in px)
*/

@mixin bordered($color: var(--color__text) , $color--hover: var(--color__text--light), $pos: 98%) {

  // $color--opacity: rgba($color, 0.5);
  $color--opacity: $color;

  text-decoration: none;
  background-image: linear-gradient(to bottom, #{$color--opacity} 75%, #{$color--opacity} 75%);
  background-repeat: repeat-x;
  background-size: 1px 1px;
  background-position: 0 $pos;

  @if $color--hover != false {
    &:hover {
      background-image: linear-gradient(to bottom, #{$color--hover} 75%, #{$color--hover} 75%);
    }
  }
}
