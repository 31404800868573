$column-spans__hubIntro: (
    xsmall: 6,
    small: 6,
    medium: 6,
    large: 6,
    xlarge: 6
);

.hubIntro {
  @include columns-container-flex;
  margin-bottom: var(--spacing-13);

  @include breakpoint('small+') {
    margin-bottom: var(--spacing-15);
  }

  > * {
    @include column-flex($column-spans__hubIntro);
    margin-top: var(--spacing-12);
  }
}

.hubHeader--branded + .content .hubIntro > * {
  margin-top: var(--spacing-12);
}
