$column-spans__hub: (
    xsmall: 6,
    small: 3,
    medium: 6,
    large: 4,
    xlarge: 4
);

.hub__title {
  @include f-body-02;
  color: var(--color__text--light);
  text-transform: uppercase;
}

.hub {
  @include columns-container-flex;
  margin-top: var(--spacing-10);

  @include breakpoint('small+') {
    margin-top: var(--spacing-13);
  }
}

.hubCollection {
  margin-top: var(--spacing-10);

  @include breakpoint('small+') {
    margin-top: var(--spacing-13);
  }
}

.hub__title + .hub {
  margin-top: var(--spacing-05);

  @include breakpoint('small+') {
    margin-top: var(--spacing-08);
  }
}

.hub__item {
  @include column-flex($column-spans__hub);
  position: relative;
  margin-bottom: var(--spacing-05);
  border-radius: $border-radius;
  border: 1px solid var(--color__background--dark);
  overflow: hidden;

  .hub__bg {
    position: relative;
    display: block;
    padding-bottom: (460 / 360) * 100%;
    background: var(--color__background);
  }

  @each $name, $point in $breakpoints {
    @include breakpoint('#{$name}') {
      margin-bottom: map-get($inner-gutters, $name);
    }
  }
}
